import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({

  container: {
    marginLeft: '3%',
    marginRight: '3%',
    backgroundColor: 'white'
  },

  content: {
    height: '50vh', 
    overflowY: 'scroll',
    paddingLeft: '10%',
    paddingRight: '10%',
    
  },

  header: {
    textAlign: 'center',
    paddingBottom: '15px',
    paddingTop: '40px'
  },

  contentHeader: {
    color: '#0077B3'
  },

  contentSubHeader: {
    color: '#FF914D'
  },

  list: {
    listStyle: 'none'
  }
}));