import MUIButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

/*
Custom styled Button components that can be used throughout the application
*/

export const Button = withStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
    color: "white",
    background:"radial-gradient(73.95% 73.95% at 14.58% 11.46%,#ee8f37 0,#e47029 100%)",
    border: "1px solid #ee8f37",
    padding: '6px 24px',
    fontWeight: 700,
    '&:hover': {
      opacity: 1,
      color: '#767676',
      background: "white",
    },
  },
}))(MUIButton);

export const OutlinedOrangeButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.orange,
    border: `1.5px solid ${theme.palette.primary.orange}`,
    '&:hover': {
      opacity: 1
    },
  },
}))(MUIButton);

export const OutlinedOrangeButtonLarge = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.orange,
    border: `1.5px solid ${theme.palette.primary.orange}`,
    '&:hover': {
      opacity: 1
    },
    padding: '12px 20px 12px 20px'
  },
}))(MUIButton);

export const OrangeButton = withStyles((theme) => ({
  root: {
    color: 'White',
    backgroundColor: "#cb4d01",
    width: "100%",
    marginBottom: "15px",
    fontWeight:700,
    '&:hover': {
      opacity: 1,
      color: '#767676',
      backgroundColor: "white",
      width: "100%",
      marginBottom: "15px",
      border:"1px solid #cb4d01",
      fontWeight:700,
    },
  },
}))(MUIButton);

export const PlainButton = withStyles((theme) => ({
  root: {
    '&:hover': {
      opacity: 1
    },
    textTransform: 'none'
  },
}))(MUIButton);
