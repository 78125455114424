import React from "react";

export default function TimeExpiredError() {
    return (
        <div style={{backgroundColor:"white",padding:"30px", boxShadow: "1px 1px 1px 1px #e47029"}}>
            <h1>
                Hello, <span style={{color:"#347abc"}}>Patient!</span>
            </h1>
            <div className="h-text">
                <h4>
                    <br />
                    Sorry! The link to your appointment has expired.
                    <br />
                    <br />
                    Please contact your clinic to reschedule or book a new appointment.
                </h4>
            </div>
        </div>
    );
}
