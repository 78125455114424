import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    wrapper: {
        minWidth: '300px',
        minHeight: '180px',
        textAlign: 'center',
        padding: '25px',
    },
    icon: {
        color: '#71BDA4', 
        fontSize: '80px',
        paddingBottom: '10px',
    },
    title: {
        fontSize: '20px',
        fontWeight: '500',
        paddingBottom: '10px',
    },
    content: {
        fontSize: '15px',
        padding: '0 5px',
        margin: 0,
        lineHeight: '16px',
        fontWeight: '400',
        color: '#767676',
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '20px',
        "& button:nth-child(2)": {
            marginLeft: '50px'
        }
    },
    button: {
        marginBottom: '10px',
    },
});

export default useStyles;
