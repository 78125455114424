import React, { useState, useEffect, useRef } from "react";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from '../../../styles/MeetingHistoryStyle/components/DropDownCalendar'
import moment from 'moment';
import {
  Paper,
  Box,
  IconButton,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";



function getMonthFromYearAndData(year, dates) {
  let monthListTemp = [];

  dates.forEach((date) => {
    if (date.year !== year) {
      return;
    }

    let foundMonth = monthListTemp.find(
      (e) => date.year === year && date.month === e.month
    );
    if (!foundMonth) {
      monthListTemp.push({
        month: date.month,
        monthText: date.monthText,
      });
    }
  });

  monthListTemp.sort((obj1, obj2) => {
    // * 1 to conver text to number
    let year1 = obj1["year"] * 1;
    let year2 = obj2["year"] * 1;

    return year1 < year2 ? 1 : year1 === year2 ? 0 : -1;
  });
  console.log("monthList temp ", monthListTemp);
  return monthListTemp;
}

export default function DropDownCalendar(props) {
  let classes = useStyles();
  const { dates, onChange, initial } = props;

  const [isCalanderShow, setIsCalanderShow] = useState(false);
  const [monthList, setMonthList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [currentDate, setCurrentDate] = useState(null); // last date is the first day to show first
  const [yearDisplay, setYearDisplay] = useState();
  const calendarRef = useRef(null);

  const handleCalendarShowButton = () => {
    setIsCalanderShow((isShow) => {
      return !isShow;
    });
  };

  const handleMonthBtnClick = (month, monthText) => {
    setIsCalanderShow(false);
    let newCurrentDate = {
      year: yearDisplay,
      month,
      monthText,
    };
    onChange(newCurrentDate);
    setCurrentDate(newCurrentDate);
  };

  const handleArrClick = (year, direction) => {
    let index = yearList.indexOf(year);
    if (direction === "left") {
      if (index <= 0) {
        return;
      } else {
        index--;
      }
    } else {
      if (index >= yearList.length - 1) {
        return;
      } else {
        index++;
      }
    }

    setYearDisplay(yearList[index]);
    setMonthList(getMonthFromYearAndData(yearList[index], dates));
  };

  const handleEmptySpaceClick = (event) => {
    // this is for error handling if the page do not exist the history page app
    if (!calendarRef || !calendarRef.current) {
      return;
    }

    if (calendarRef.current.contains(event.target)) {
      return;
    }
    setIsCalanderShow(false);
  };

  useEffect(() => {
    let lastDate = dates[dates.length - 1];
    if(initial)
    {
      setCurrentDate({
        year:initial.split(":")[1],
        month: initial.split(":")[0],
        monthText: moment(initial.split(':')[0], 'M').format('MMM')        
      })
    }
    else
      setCurrentDate(lastDate);

    document.addEventListener("click", handleEmptySpaceClick);
    return () => {
      document.removeEventListener("click", handleEmptySpaceClick);
    };
  }, []);

  useEffect(() => {
    let lastDate = dates[dates.length - 1];
    // let lastDate = dates[0]
    
    if (!currentDate) 
    {
      setCurrentDate(lastDate);
      if(initial)
      {
        setCurrentDate({
          year:initial.split(":")[1],
          month: initial.split(":")[0],
          monthText: moment(initial.split(':')[0], 'M').format('MMM')        
        })
        setYearDisplay(initial.split(":")[1]);
      }
      else if(lastDate)
        setCurrentDate(lastDate);
    }

    let monthListTemp = [];
    let yearListTemp = [];

    dates.forEach((date) => {
      // set up year list
      let foundYear = yearListTemp.find((e) => date.year === e);
      if (!foundYear) {
        yearListTemp.push(date.year);
      }

      if (!currentDate) {
        return;
      }

      if (!currentDate || currentDate.year !== date.year) {
        return;
      }

      let foundMonth = monthListTemp.find(
        (e) =>
          currentDate && date.year === currentDate.year && date.month === e.month
      );
      if (!foundMonth) {
        monthListTemp.push({
          month: date.month,
          monthText: date.monthText,
        });
      }
    });

    yearListTemp.sort();
    monthListTemp.sort((obj1, obj2) => {
      // * 1 to conver text to number
      let year1 = obj1["year"] * 1;
      let year2 = obj2["year"] * 1;

      return year1 < year2 ? 1 : year1 === year2 ? 0 : -1;
    });

    setYearList(yearListTemp);
    setMonthList(monthListTemp);
  }, [currentDate]);

  return (
    <Box ref={calendarRef}>
      <Paper elevation={3} className={classes.calendarButton}>
        <Button endIcon={<ExpandMoreIcon />} onClick={handleCalendarShowButton}>
          {currentDate
            ? `${currentDate.monthText} ${currentDate.year}`
            : "no history"}
        </Button>
      </Paper>

      <Box
        className={
          classes.calendarBody +
          " " +
          (isCalanderShow
            ? classes.calendarBodyShow
            : classes.calendarBobyHidden)
        }
      >
        <Paper elevation={3} className={classes.calendar}>
          <Box className={classes.calendarHeader}>
            <IconButton
              onClick={() => {
                handleArrClick(yearDisplay, "left");
              }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography component="span">
              {currentDate ? yearDisplay : new Date().getYear()}
            </Typography>
            <IconButton
              onClick={() => {
                handleArrClick(yearDisplay, "right");
              }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>

          <Box>
            <Grid container>
              {monthList.map((month) => {
                return (
                  <Grid item sm={4} key={month.month}>
                    <Button
                      onClick={() => {
                        handleMonthBtnClick(month.month, month.monthText);
                      }}
                    >
                      {month.monthText.toUpperCase()}
                    </Button>
                  </Grid>
                );
              })}

              {/*<Grid item sm={4}>
                <Button>JAN</Button>
              </Grid>
               <Grid item sm={4}>
                <Button>FEB</Button>
              </Grid>
               <Grid item sm={4}>
                <Button>MAR</Button>
              </Grid>
               <Grid item sm={4}>
                <Button>APR</Button>
              </Grid>
               <Grid item sm={4}>
                <Button>MAY</Button>
              </Grid>
               <Grid item sm={4}>
                <Button>FUN</Button>
              </Grid>
               <Grid item sm={4}>
                <Button>JUL</Button>
              </Grid>
               <Grid item sm={4}>
                <Button>AUG</Button>
              </Grid>
               <Grid item sm={4}>
                <Button>SEP</Button>
              </Grid>
               <Grid item sm={4}>
                <Button>OCT</Button>
              </Grid>
              <Grid item sm={4}>
                <Button>NOV</Button>
              </Grid>
              <Grid item sm={4}>
                <Button>DEC</Button>
              </Grid>*/}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
