import React from 'react'
import { Link } from 'react-router-dom'
import PortonLogo from '../../shared/public/img/portonmain.png'
import useStyles from '../../../styles/LoginStyle/LoginRouting';


export default function Header() {
  const classes = useStyles();
  return (
    <header className={classes.header}>
      <Link to='/'><img className={classes.portonImgHeader} src={PortonLogo} alt="Porton"/></Link>
    </header>
  )
}
