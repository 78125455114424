import React, { useRef, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { SoundMeter } from '../../shared/utilities/SoundMeter';
import useStyles from '../../../styles/PreCallTestStyle/Audio';
import CustomAlert from '../../shared/components/CustomAlert';
import clsx from 'clsx';
// Other 
import { detect } from 'detect-browser';

export default function Audio({ devicesSelection, permissions }) {

  const instantMeter = useRef(null);
  const instantValueDisplay = useRef(null);
  const [error, setError] = useState('');
  const [errorDuration, setErrorDuration] = useState(4000);
  const [openAlert, setOpenAlert] = useState(false);
  const [currentAudioTrack, setCurrentAudioTrack] = useState('');
  const browser = detect();
  const LOW_VOLUME_THRESHOLD_VALUES = {thresholdDecibel: -85.00, interval: 9000}

  const classes = useStyles();
  let meterRefresh = useRef(null);
  let lowVolumeTest = useRef(null);
  let constraints = useRef(window.constraints = { audio: true, video: false });

  useEffect(() => {
    start();

    return () => {
      clearInterval(meterRefresh.current);
      clearInterval(lowVolumeTest.current);
      stop();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (permissions.audio === 'granted') {
      start();
    }

    return () => {
      clearInterval(meterRefresh.current);
      clearInterval(lowVolumeTest.current);
      stop();
    }
    // eslint-disable-next-line
  }, [permissions])

  useEffect(() => {

    if(devicesSelection.audioIn !== currentAudioTrack && currentAudioTrack) {

      clearInterval(meterRefresh.current);
      clearInterval(lowVolumeTest.current);
      stop();

      navigator.mediaDevices.enumerateDevices().then(devices => {
        let audioInId;
        devices.forEach(device => {
          if(device.label === devicesSelection.audioIn && device.kind === 'audioinput') {
            audioInId = device.deviceId;
          }
        });

        // New constraints
        constraints.current = window.constraints = {
          audio: {deviceId: audioInId ? {exact: audioInId} : undefined},
          video: false
        };

        start();
      });
    }
    // eslint-disable-next-line
  }, [devicesSelection]);

  const start = () => {
    try {
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      window.audioContext = new AudioContext();
    } catch (e) {
      setError('Web Audio API not supported.');
    }

    navigator.mediaDevices
    .getUserMedia(constraints.current)
    .then(handleSuccess)
    .catch(handleError);
  };

  function handleSuccess(stream) {
    setOpenAlert(false)
    window.stream = stream;
    setCurrentAudioTrack(stream.getAudioTracks()[0].label);
    const soundMeter = window.soundMeter = new SoundMeter(window.audioContext, window.audioContext.createAnalyser());
    soundMeter.analyser.smoothingTimeConstant = 0.9;
    const volumeArray = new Float32Array(40) // First 40 Values of Frequency equal 0-8600Hz which is the range of the human voice

    soundMeter.connectToSource(stream, (e) => {
      if (e) {  
        console.log("Error: ", e);
        return;
      }
      meterRefresh.current = setInterval(() => {
        instantMeter.current.value = instantValueDisplay.current.innerText = soundMeter.instant.toFixed(2);
        soundMeter.analyser.getFloatFrequencyData(volumeArray)
      }, 200);

      lowVolumeTest.current = setInterval(() => {
        let averageVol = averageVolumeLevel(volumeArray)
        
        if (averageVol === -Infinity && !openAlert) {
          setError("No audio is being detected from your microphone. Please check if it is muted or switch to a different audio device.");
          setErrorDuration(8000)
          setOpenAlert(true)
        }
        else if (averageVol < LOW_VOLUME_THRESHOLD_VALUES.thresholdDecibel && !openAlert) {
          setError("Your audio is very low. Please move the microphone closer.");
          setErrorDuration(3000)
          setOpenAlert(true)
        }
      }, LOW_VOLUME_THRESHOLD_VALUES.interval);

    });
  };

  const stop = () => {
    if (typeof window.stream !== 'undefined') {
      window.stream.getAudioTracks().forEach(track => track.stop());
      window.soundMeter.stop();
    }
  } 

  const averageVolumeLevel = (array) => {
    return array.reduce((a, b) => a + b) / array.length;
  }

  const handleError = (error) => {
    console.log("ERROR: " + error)
    if (error.name === 'PermissionDeniedError' || error.name === 'NotAllowedError') {
      setErrorDuration(8000);
      if (browser.name === 'safari') {
        setError('Permissions have not been granted to use your camera and microphone. Please grant permission and reopen the pre-call test.');
      } else {
        setError('Permissions have not been granted to use your camera and microphone. Please grant permission');
      }
    } else if(error.name === "NotFoundError"){
      setError('Your microphone could not be detected. Please try reconnecting or resetting and try again');
    } else {
      setError(`Audio error: ${error.name}`);}
  };

  return (
    <>
      <div className={classes.container}>
        <Typography variant='h4'>Mic Volume</Typography>
        <div style={{display: 'flex'}}>
          <meter ref={instantMeter} className={clsx(classes.meter, classes.instant)} high="0.25" max="1" low="0.1" value="0"></meter>
          <div ref={instantValueDisplay} style={{padding: '1px'}}></div>
          </div>
      </div>
    <CustomAlert description={error} type="error" openAlert={openAlert} setOpenAlert={setOpenAlert} duration={errorDuration}/>
    </>
  );
}
