import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({

  videoStreamContainer: {
    width: '507px',
    height: '320px',
    display: 'inline-block',
    '@media screen and (max-width:960px)': {
      marginLeft: '1%',
      marginBottom: '0%'
    },
    '@media screen and (max-width:768px)': {
      width: '350px',
      height:'auto',
      marginLeft: '1%',
      marginBottom: '1%',
      paddingLeft:'4px',
     
    },
    '@media screen and (max-width:885px)': {
      width: '440px',
      height:'auto',
      marginLeft: '1%',
      marginBottom: '1%'
    },
    '@media screen and (max-width:425px)': {
      width: '300px',
      height:'auto',
      marginLeft: '3%',
      marginBottom: '2%'
    },
    '@media screen and (max-width:375px)': {
      width: '270px',
      height:'auto',
      marginLeft: '3%',
      marginBottom: '5%', paddingLeft: '2px',
    },
   },

  videoStream: {
    width: 'auto',
    height: '310px',
    margin:'0px',
    '@media screen and (max-width:885px)': {
      width: '350px', height:'auto'
      
    },
    '@media screen and (max-width:768px)': {
      width: '350px', height:'auto', margin: '1%',paddingLeft: '8px',
      
    },
    '@media screen and (max-width:425px)': {
      width: '300px',
      height: 'auto', margin:'0px',
    },
    '@media screen and (max-width:375px)': {
      width: '270px',
      height: 'auto',
      paddingLeft: '1px',
    },
   
  },

  videoLoading: {
    position: 'relative',
    top: '50%',
    left: '50%'
  },

}));