import { TableCell, makeStyles, withStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBox: {
        margin: "2rem 0",
    },
    apt: {
        padding: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        "& p": {
            padding: 15,
        }
    },
    todaysAppointments: {
        padding: 32,
    },
    aptCell: {
        marginTop: 22,
    },
    columnPaper: {
        maxHeight: 400,
        overflowX: "hidden",
    },
    labelLarge: {
        fontSize: "32px",
        color: "black",
        fontWeight: 500,
        width: "100%",
        margin:"auto 0",
    },
    imageText: {
        marginBottom: "30px",
    },
    rowWrapper: {
        "@media (max-width: 768px)": {
            borderBottom: "1px solid #d2cbcb",
        },
    },
    cellWrapper: {
        "& strong": {
            display: "none",
        },

        "@media (max-width: 768px)": {
            display: "inline-flex",
            width: "45%",
            border: "none",
            padding: "10px 15px",
    
            "& > div": {
                display: "flex",
                flexDirection: "column",
            },

            "& strong": {
                display: "block",
            },
        },
   
        "@media (max-width: 425px)": {
            width: "100%",
        }
    },
    iconCell: {
        display: "flex",
        
        "& svg": {
            paddingRight: "5px",
        }
    },
}));

export const StyledTableHeadCell = withStyles({
    root: {
        fontSize: "1.25em",
        fontWeight: "bold",
        paddingTop: "20px",
        paddingBottom: "20px",

        '@media (max-width: 768px)': {
            display: "none",
        },
    },
  })(TableCell);
  