export class SoundMeter {

  constructor (context, analyser) {
    this.context = context;
    this.analyser = analyser;
    this.instant = 0.0;
    this.script = context.createScriptProcessor(2048, 1, 1);
    // To access this.instant inside the function scope
    const that = this;

    this.script.onaudioprocess = (event) => {
      const input = event.inputBuffer.getChannelData(0);
      let i;
      let sum = 0.0;

      for (i = 0; i < input.length; ++i) {
        // You can use this factor to change the mic sensitivity
        sum += input[i] * input[i] * 50;
      }
      that.instant = Math.sqrt(sum / input.length);
    } 
  };

  connectToSource(stream, callback) {
    try {
      this.mic = this.context.createMediaStreamSource(stream);
      this.mic.connect(this.script);
      this.mic.connect(this.analyser)
      // necessary to make sample run, but should not be.
      this.script.connect(this.context.destination);
      if (typeof callback !== 'undefined') {
        callback(null);
      }
    } catch (e) {
      console.error(e);
      if (typeof callback !== 'undefined') {
        callback(e);
      }
    }
  };

  stop() {
    this.mic.disconnect();
    this.script.disconnect();
  };

}
  