import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({

  container: {
    width: '30%',
    margin: 'auto',
    padding: '40px',
    marginTop: '60px'
  },

  header: {
    fontWeight: '400', 
    color: 'white'
  },

  title: {
    textAlign: 'center'
  },

  content: {
    textAlign: 'center'
  },

  subHeading: {
    color: '#FF914D'
  }
}));