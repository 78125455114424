import { createTheme } from "@material-ui/core/styles";

export default createTheme({

    status: {
        danger: "#666666"
    },
    palette: {
        primary: {
            main: "#003FBA",
            orange: "#CB4D00",
        },
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 8,
                fontWeight: 500,
                fontSize: 16,
                '@media (max-width:600px)': {
                  fontSize: 14,
                },
            },
        }
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
        h2: {
          fontWeight: '400',
          '@media (max-width:960px)': {
            fontSize: '3.75rem',
          },
          '@media (max-width:600px)': {
            fontSize: '3rem',
          },
          '@media (max-width:425px)': {
            fontSize: '2rem',
          },
        },
        h3: {
          fontSize: '2rem',
          fontWeight: '500',
          '@media (min-width:425px)': {
            fontSize: '1.8rem',
          },
          '@media (min-width:600px)': {
            fontSize: '2.25rem',
          },
          '@media (min-width:960px)': {
            fontSize: '2.5rem',
          },
        },
        h4: {
          fontSize: '1rem',
          fontWeight: '400',
          '@media (min-width:600px)': {
            fontSize: '1rem',
          },
          '@media (min-width:960px)': {
            fontSize: '1.25rem',
          },
        },
        h5: {
          fontSize: '1rem',
          fontWeight: '500',
          '@media (min-width:425px)': {
            fontSize: '1rem',
          },
          '@media (min-width:600px)': {
            fontSize: '1.2rem',
          },
          '@media (min-width:960px)': {
            fontSize: '1.4rem',
          },
        },
        h6: {
          fontSize: '1rem',
          fontWeight: '500',
          '@media (min-width:425px)': {
            fontSize: '0.9rem',
          },
          '@media (min-width:600px)': {
            fontSize: '1.2rem',
          },
          '@media (min-width:960px)': {
            fontSize: '1.4rem',
          },
        },
        body1: {
          color: '#767676',
          fontWeight: '500',
          fontSize: '1.25rem',     
        },
        body2: {
          color: 'black',
          fontWeight: '500',
          fontSize: '1rem',
        },
    
      }
});
