import React from 'react'
import { Grid, Divider }  from '@material-ui/core';
import useStyles from '../../../styles/MeetingHistoryStyle/Log';
import moment from 'moment';
// Required to get time difference (duration)
// eslint-disable-next-line
const momentDurationFormatSetup = require('moment-duration-format');


export default function Log({ meetings }) {

  const classes = useStyles();

  return (
    <>
    <Grid container>
      <Grid container spacing={2} alignItems="center" justifyContent="center" className={classes.tableContainerHeader}>
          <Grid item xs>
            Patient
          </Grid>
          <Grid item xs>
            Date
          </Grid>
          <Grid item xs>
            Start Time
          </Grid>
          <Grid item xs>
            End Time
          </Grid>
          <Grid item xs>
            Duration
          </Grid>
      </Grid>
    </Grid>

    <Divider />

    <Grid container>
      {
        meetings.map((meeting, i) => (
          <Grid container key={i} spacing={2} alignItems="center" className={i % 2 !== 0 ? classes.tableContaineBodyColor : classes.tableContaineBodyWhite}>
          <Grid item xs>
            {meeting.patient}
          </Grid>
          <Grid item xs>
            {`
            ${moment(meeting.start_time).format('dddd').substr(0,3)}, 
            ${moment(meeting.start_time).format("MMMM")} 
            ${moment(meeting.start_time).format("D")}
            ${moment(meeting.start_time).format("Y")}
            `} 
          </Grid>
          <Grid item xs>
            {moment(meeting.start_time).format('H:mm:ss A')}
          </Grid>
          <Grid item xs>
            {moment(meeting.end_time).format('H:mm:ss A')}
          </Grid>
          <Grid item xs>
            {moment.duration(moment(meeting.end_time).diff(moment(meeting.start_time))).format('H:mm:ss')}
          </Grid>
        </Grid>
        ))  
      }

    </Grid>
   </>
  )
}
