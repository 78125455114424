import {
  Button,
  TextField,
  DialogContent,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import JSZip from 'jszip';
import mime from 'mime';
import React, { useState } from 'react';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Report1 from '../../img/getReportCarousel/1.jpeg';
import Report2 from '../../img/getReportCarousel/2.jpg';
import Report3 from '../../img/getReportCarousel/3.png';
import Report4 from '../../img/getReportCarousel/4.jpg';
import Report5 from '../../img/getReportCarousel/5.jpg';
import Report6 from '../../img/getReportCarousel/6.jpg';
import Report7 from '../../img/getReportCarousel/7.jpeg';
import Report8 from '../../img/getReportCarousel/8.jpg';
import Report9 from '../../img/getReportCarousel/9.jpg';
import Report10 from '../../img/getReportCarousel/10.jpg';
import Report11 from '../../img/getReportCarousel/11.jpg';
import Report12 from '../../img/getReportCarousel/12.jpg';
import Report13 from '../../img/getReportCarousel/13.jpeg';
import Report14 from '../../img/getReportCarousel/14.jpeg';

import Slider from 'react-slick';
import apiConfigs from '../shared/utilities/apiConfigs';
import { api } from '../shared/utilities/api';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F5F6FA',
    minHeight: '36vh',
    maxHeight: '48vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 768px)': {
      minHeight: '20vh',
      maxHeight: '30vh',
    },
  },
  form: {
    padding: '20px 35px',
  },
  autoComplete: {
    width: '100%',
    marginBottom: '16px',
  },
  heading: {
    textAlign: 'center',
    fontSize: 'xx-large',
    fontFamily: 'Roboto',
    '@media (max-width: 768px)': {
      fontSize: 'x-large',
    },
  },
  loadingCircle: {
    textAlign: 'center',
    margin: '10px',
    color: '#1766b3',
  },
  requirementsContainer: {
    marginTop: '10px',
  },
  requirementsList: {
    paddingLeft: '20px',
  },
  expandIcon: {
    verticalAlign: 'middle',
    marginLeft: '5px',
    cursor: 'pointer',
  },
  submitButton: {
    color: 'white',
    borderRadius: '8px',
    marginBottom: '10px',
    padding: '10px 70px',
    '@media (max-width: 768px)': {
      padding: '8px',
    },
  },
  info: {
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: '600',
    fontFamily: 'Roboto',
    '@media (max-width: 768px)': {
      fontSize: '0.7rem',
    },
  },
  fileNameText: {
    fontSize: '14px',
    color: '#333',
    margin: 0,
    '@media (max-width: 768px)': {
      fontSize: '12px',
    },
  },
  fileDowloadButton: {
    fontSize: '14px',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
    '@media (max-width: 768px)': {
      padding: '5px',
      fontSize: '12px',
    },
  },
  getReportTextSpacing: {
    width: '80%',
    marginBottom: '10px',
    marginLeft: '2rem',
    marginRight: '70px',
    marginTop: '1rem',
    '@media (max-width: 768px)': {
      marginLeft: '1rem',
    },
  },

  downloadAllButton: {
    background:
      'radial-gradient(73.95% 73.95% at 14.58% 11.46%, #ee8f37 0, #e47029 100%)',
    border: 'none',
    borderRadius: '8px',
    boxShadow: '0 0 32px rgba(255, 145, 77, 0.25)',
    color: 'white',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.5px',
    lineHeight: '19px',
    padding: '2px 12px',
    marginTop: '10px',
  },
  info2: {
    fontSize: '16px',
    lineHeight: '1.5',
    color: '#333',
    '@media (max-width: 768px)': {
      fontSize: '12px',
    },
  },

  rowTextMargin: {
    marginRight: '2%',
  },
  responsiveSpan: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 768px)': {
      display: 'block',
      justifyContent: 'initial',
      marginLeft: '1rem',
    },
    getReportTextSpacing: {
      width: '30%',
      marginBottom: '10px',
      marginLeft: '2rem',
      marginRight: '70px',
      '@media (max-width: 768px)': {
        width: '80%',
        marginTop: '1rem',
        marginLeft: '2rem',
      },
    },
  },
}));

const GetPatientReportForm = ({
  orgId,
  orgName,
  hashedId,
  sessionId,
  logo,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [downloadButtons, setDownloadButtons] = useState([]);
  const [displayFileList, setDisplayFileList] = useState(false);
  const [values, setValues] = useState({
    hin: '',
    dob: '',
  });

  const [error, setError] = useState({
    hin: false,
    dob: false,
  });

  const images = [
    Report1,
    Report2,
    Report3,
    Report4,
    Report5,
    Report6,
    Report7,
    Report8,
    Report9,
    Report10,
    Report11,
    Report12,
    Report13,
    Report14,
  ];

  const disableButton = () => {
    return error.hin || error.dob;
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // Show the arrows for navigation
    prevArrow: <div className="slick-prev">Previous</div>, // Custom left arrow
    nextArrow: <div className="slick-next">Next</div>, // Custom right arrow
    autoplay: true, // Enable automatic image rotation
    autoplaySpeed: 2000, // Set the interval for image rotation (in milliseconds)
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      // Fetch data using `apiConfigs` to configure the request
      const fetchFiles = await api(
        apiConfigs.baseOption('post', 'file/retrieveFilesFromSessionId', {
          data: {
            orgName,
            hashedId,
            sessionId,
            hin: values.hin,
            dob: values.dob,
          },
        })
      );

      // Handle successful response
      if (fetchFiles && fetchFiles.status === 200) {
        const fileInfoArray = fetchFiles.data.map((fileInfo) => {
          const { fileName, fileType, buffer } = fileInfo;
          const arrayBuffer = new Uint8Array(buffer).buffer;
          const contentType =
            mime.getType(fileType) || 'application/octet-stream';
          const blob = new Blob([arrayBuffer], { type: contentType });

          const url = URL.createObjectURL(blob);

          return {
            fileName,
            url,
          };
        });

        setDownloadButtons(fileInfoArray);
        setDisplayFileList(true);
      } else if (fetchFiles.status === 404) {
        alert('User is not valid or not found');
      } else {
        alert('Unable to retrieve report');
      }
    } catch (error) {
      console.error('Error retrieving files:', error);
      // Handle error, show error message or fallback behavior
    } finally {
      setLoading(false);
      setValues({
        ...values,
        hin: '',
        dob: '',
      });
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id === 'hin') {
      if (/^\d*$/.test(value) && value.length <= 10) {
        setValues((prevValues) => ({
          ...prevValues,
          hin: value,
        }));
      }
    } else if (id === 'dob') {
      let formattedValue = value.replace(/[^0-9]/g, '');

      if (formattedValue.length > 4) {
        formattedValue =
          formattedValue.slice(0, 4) + '-' + formattedValue.slice(4);
      }
      if (formattedValue.length > 7) {
        formattedValue =
          formattedValue.slice(0, 7) + '-' + formattedValue.slice(7, 9);
      }
      setValues((prevValues) => ({
        ...prevValues,
        dob: formattedValue,
      }));
    }
  };

  const handleDownloadAll = async () => {
    const zip = new JSZip();

    // Iterate through the list of files and add them to the zip archive.
    for (const fileInfo of downloadButtons) {
      const response = await fetch(fileInfo.url);
      const data = await response.arrayBuffer();

      // Add the file to the zip archive with the desired file name.
      zip.file(fileInfo.fileName, data);
    }

    // Generate the zip file as a Blob.
    const zipBlob = await zip.generateAsync({ type: 'blob' });

    // Create a temporary link and trigger the download of the zip file.
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(zipBlob);
    downloadLink.download = 'downloaded-files.zip';
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div>
      <Slider {...sliderSettings}>
        {images.map((image, index) => (
          <img
            key={index}
            className={classes.root}
            src={image}
            alt="Medical Record"
          />
        ))}
      </Slider>

      <div className={classes.form}>
        <div
          style={{
            display: 'flex',
            justifyContent: displayFileList ? 'space-between' : 'center',
            alignItems: 'center',
          }}
        >
          {displayFileList ? <div style={{ width: '64px' }}></div> : null}
          <h1 className={classes.heading}>GET YOUR REPORTS</h1>
          {displayFileList && (
            <Button
              elevation={10}
              style={{
                marginTop: '10px',
                marginBottom: '10px',
                padding: '2px 4px',
                color: '#FFF',
                background:
                  'radial-gradient(73.95% 73.95% at 14.58% 11.46%, #ee8f37 0, #e47029 100%)',
                border: 'none',
                borderRadius: '8px',
                boxShadow: '0 0 32px rgba(255, 145, 77, 0.25)',
              }}
              variant="text"
              size="small"
              startIcon={<ExitToAppIcon />}
              onClick={() => {
                window.location.reload();
              }}
              hidden={true}
            >
              EXIT
            </Button>
          )}
        </div>

        {!displayFileList ? (
          <div>
            <p className={classes.info}>
              Kindly insert your Health Insurance Number and Date of Birth to
              download your files securely.
            </p>
            <span className={classes.responsiveSpan}>
              <TextField
                id="hin"
                label="HIN"
                multiline
                placeholder={'1234567890'}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: '#000000',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto',
                    resize: 'both',
                  },
                }}
                variant="outlined"
                value={values.hin}
                size="small"
                onChange={handleChange}
                onBlur={(e) => {
                  setError({ ...error, hin: !/^\d{10}$/.test(values.hin) });
                }}
                className={clsx(
                  classes.getReportTextSpacing,
                  classes.rowTextMargin
                )}
                required
                error={error['hin']}
              />
              <TextField
                id="dob"
                placeholder={'YYYY-MM-DD'}
                label="DOB"
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: '#000000',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto',
                  },
                }}
                variant="outlined"
                value={values.dob}
                size="small"
                onChange={handleChange}
                onBlur={(e) => {
                  setError({
                    ...error,
                    dob: !/^\d{4}-\d{2}-\d{2}$/.test(values.dob),
                  });
                }}
                className={classes.getReportTextSpacing}
                required
                error={error['dob']}
              />
            </span>

            <DialogContent
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {!loading ? (
                <Button
                  color="primary"
                  disabled={disableButton()}
                  size="large"
                  className={classes.submitButton}
                  style={{
                    backgroundColor: disableButton() ? 'lightgrey' : '#EA8331',
                  }}
                  onClick={handleSubmit}
                >
                  Get Report
                </Button>
              ) : (
                <CircularProgress
                  style={{
                    margin: '5px',
                    color: '#1766b3',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  size={30}
                />
              )}
            </DialogContent>
          </div>
        ) : (
          <div>
            <p className={classes.info2}>
              Your clinic has sent you some files, and they are ready for
              download. Simply click on the{' '}
              <b style={{ color: '#007bff' }}>"Download"</b> button below to
              access each file. Please note that these links will be active for
              72 hours only, so we recommend downloading your important
              documents promptly. If you have any questions or require
              assistance, kindly contact your clinic.
            </p>

            {downloadButtons.length && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: '20px',
                }}
              >
                <Tooltip placement={'top'} title={'Download All files'}>
                  <button
                    className={classes.downloadAllButton}
                    onClick={handleDownloadAll}
                  >
                    {' '}
                    Download All
                  </button>
                </Tooltip>{' '}
              </div>
            )}
            <div>
              {downloadButtons.map((fileInfo, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                    backgroundColor: '#f5f5f5',
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                  }}
                >
                  <p className={classes.fileNameText}>{fileInfo.fileName}</p>
                  <button className={classes.fileDowloadButton}>
                    <a
                      href={fileInfo.url}
                      download
                      style={{ color: '#fff', textDecoration: 'none' }}
                    >
                      Download
                    </a>
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GetPatientReportForm;
