import React from 'react';
import HumburgerButton from "../shared/HumburgerButton";

const Upgrade = ({ smallScreenView, handleMenuClick }) => {
    return (
        <div>
            <HumburgerButton smallScreenView={smallScreenView} handleMenuClick={handleMenuClick} />
          <div>
            <p>Upgrade</p>
            </div>
        </div>
    );
}

export default Upgrade;
