/*
 * File: crypt.js
 * Project: porton-health-api
 * File Created: Wednesday, 29th April 2020 10:52:04 am
 * Author: Temitayo Bodunrin (temitayo@camelcase.co)
 * -----
 * Last Modified: Thursday, 16th July 2020 6:05:18 pm
 * Modified By: Temitayo Bodunrin (temitayo@camelcase.co)
 * -----
 * Copyright 2020, CamelCase Technologies Ltd
 */

const CryptoJS = require('crypto-js');


const passphrase = "A0No1t3Very5Secure8Pass5ThaT09!IasAm09Using99Is=This-Long8Enuf>?";

export function decrypt(val) {
  if(val) {
    return CryptoJS.AES.decrypt(val, passphrase).toString(CryptoJS.enc.Utf8)
  }
}

export function encrypt(value) {
  return value ? CryptoJS.AES.encrypt(value, passphrase).toString() : "";
}