
const virtualUrl = process.env.REACT_APP_VIRTUAL_APP ? process.env.REACT_APP_VIRTUAL_APP : '';

const baseOption = (method, endpoint, { data, headers, params } = {}) => {
  const url = `${virtualUrl}/api/` + endpoint
  console.log("url",url)
  return {
    method,
    url,
    data,
    headers,
    params
  }
}

module.exports = {
  baseOption
}
