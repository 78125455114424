import React, { useState, useEffect } from 'react'
import { Typography, FormControl, Select, MenuItem, Grid, FormHelperText } from '@material-ui/core';
import CustomAlert from '../../shared/components/CustomAlert';
import useStyles from '../../../styles/PreCallTestStyle/Settings';
import { ThemeProvider}  from '@material-ui/core/styles';
import { customInputTheme } from '../../../styles/CustomThemes';
import { detect } from 'detect-browser';

export default function Settings({ mediaStream, devicesSelection, setDevicesSelection, permissions }) {

  const [videoDevices, setVideoDevices] = useState([]);
  const [audioOutDevices, setAudioOutDevices] = useState([]);
  const [audioInDevices, setAudioInDevices] = useState([]);
  const [disableAudioOut, setDisableAudioOut] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  
  const classes = useStyles();

  const getUserMedia = async () => await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

  useEffect(() => {
    // handle any browser compatability issues
    handleBrowserCompatibility();
    // Gets all available media devcies and returns an array
    getUserMedia();
    navigator.mediaDevices.enumerateDevices().then(populateDevices);
  }, []);

  useEffect(() => {
    if (permissions.audio === 'granted' && permissions.video === 'granted') {
      handleBrowserCompatibility();
      navigator.mediaDevices.enumerateDevices().then(populateDevices);
    }
  }, [permissions]);

  useEffect(() => {
    setDevicesSelection({video: mediaStream.videoTrack, audioIn: mediaStream.audioInTrack, audioOut: mediaStream.audioOutTrack});
  }, [mediaStream, setDevicesSelection]);

  const populateDevices = (devices) => {
    // Three kinds: audioinput, audiooutput, and videoinput
    let curVideo = [];
    let currAudioOut = [];
    let currAudioIn = [];

    devices.forEach(device => {
      switch(device.kind) {
        case 'audioinput':
          currAudioIn.push(device.label);
          break;
        case 'audiooutput':
          currAudioOut.push(device.label);
          break;
        case 'videoinput':
          curVideo.push(device.label);
          break;
        default:
          break;
      }
    });
    console.log(currAudioIn)
    setAudioInDevices(currAudioIn);
    setAudioOutDevices(currAudioOut);
    setVideoDevices(curVideo);
  }

  const handleBrowserCompatibility = () => {
    const browser = detect();
    // const majorVersion = parseInt(browser.version.split('.')[0]);

    // Custom audio out not supported on safari
    if (browser.name === 'safari') {
      setDisableAudioOut(true);
    }

  };

  return (
    <div>
      <Typography variant='h4' className={classes.title}> Devices </Typography>

      <ThemeProvider theme={customInputTheme}> 
        <Grid container spacing={0}>
          
          <Grid item xs={12} className={classes.rowContent}>
            <FormHelperText className={classes.label}>Video Source</FormHelperText>
            <FormControl fullWidth size ='small' variant="outlined">
              <Select
                value={devicesSelection.video}
                onChange={e => setDevicesSelection({ ...devicesSelection, video: e.target.value })}
                displayEmpty
              >
                {videoDevices.length 
                ? videoDevices.map((device, index) => <MenuItem key={index} value={device}>{device}</MenuItem>)
                  :
                    <MenuItem value={null} disabled>
                      No Devices
                    </MenuItem>
                }
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.rowContent}>
            <FormHelperText>Audio Input Source</FormHelperText>
            <FormControl  sx={{ width: '10ch' }} fullWidth size ='small' variant="outlined">
              <Select
                value={devicesSelection.audioIn}
                onChange={e => setDevicesSelection({ ...devicesSelection, audioIn: e.target.value })}
              >
                {audioInDevices.length 
                  ? audioInDevices.map((device, index) => <MenuItem key={index} value={device}>{device}</MenuItem>)
                  :
                    <MenuItem value={null} disabled>
                      No Devices
                    </MenuItem>
                }
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.rowContent}>
            <FormHelperText>Audio Output Destination</FormHelperText>
            <FormControl fullWidth size ='small' variant="outlined">
              <Select
                value={devicesSelection.audioOut}
                onChange={e => setDevicesSelection({ ...devicesSelection, audioOut: e.target.value })}
              >
                {audioOutDevices.length 
                  ? audioOutDevices.map((device, index) => <MenuItem key={index} value={device}>{device}</MenuItem>)
                  :
                    <MenuItem value={null} disabled>
                      No Devices
                    </MenuItem>
                }
              </Select>
            </FormControl>
          </Grid>
          
        </Grid>
      </ThemeProvider>
      
      {disableAudioOut && 
        <CustomAlert 
          description={"Selecting an audio output is not available on your browser."} 
          type="warning" 
          openAlert={openAlert} 
          setOpenAlert={setOpenAlert}
        />
      }
    </div>
  )
}
