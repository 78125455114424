import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
// Styled components
import { TitlePreCall, ContentPreCall, ActionsPreCall } from '../../styles/DialogStyle/components/PreCallTest';
import useStyles from '../../styles/DialogStyle/PreCallTest';
import { OrangeButton } from '../../styles/CustomButtons';
// Video/audio test componenets
import Video from './components/Video';
import Audio from './components/Audio';
import Settings from './components/Settings';
// Other 
import { detect } from 'detect-browser';
import adapter from 'webrtc-adapter';



export default function PreCallTest({showPreCallTest, setShowPreCallTest}) {

  const [mediaStream, setMediaStream] = useState({ videoTrack: '', audioInTrack: '', audioOutTrack: '' });
  const [devicesSelection, setDevicesSelection] = useState({ video: '', audioOut: '', audioIn: '' });
  const [permissions, setPermissions] = useState({audio: null, video: null});
  const classes = useStyles();
  const browser = detect();

  useEffect(() => {

    // Safari does not currently support Navigator.permissions
    // if (browser.name !== 'safari') {
    //   navigator.permissions.query({name: 'microphone'}).then(function(permission) { 
    //     permission.onchange = function() {  
    //       setPermissions(prev => ({...prev, audio: permission.state}))
    //     };
    //   });
  
    //   navigator.permissions.query({name: 'camera'}).then(function(permission) { 
    //     permission.onchange = function() {  
    //       setPermissions(prev => ({...prev, video: permission.state}))
    //     };
    //   });
    // }

    if (browser.name !== 'safari' && 
        adapter.browserDetails.version &&
        browser.os !== 'iOS' &&
        browser.os !== 'Mac OS') {

        navigator.permissions.query({name: 'microphone'}).then(function(permission) { 
          permission.onchange = function() {  
            setPermissions(prev => ({...prev, audio: permission.state}))
          };
        });
        
        navigator.permissions.query({name: 'camera'}).then(function(permission) { 
          permission.onchange = function() {  
            setPermissions(prev => ({...prev, video: permission.state}))
          };
        });
    }


  // eslint-disable-next-line
  }, []);

  // const applySettingsChanges = () => {
  //   // TODO: Save settings in DB for users and local storage for patients (temporary users)
  // }

  return (
      <Dialog classes={{ paper: classes.dialogPaper }} onClose={ () => setShowPreCallTest(false) } disableBackdropClick={true} open={showPreCallTest}>
        <TitlePreCall title="Check Your Audio and Video Devices" onClose={ () => setShowPreCallTest(false) }/>
        <ContentPreCall dividers>

          <div className={classes.container}>
            <Video
              devicesSelection={devicesSelection}
              mediaStream={mediaStream}
              setMediaStream={setMediaStream}
              permissions={permissions}
            />
            <div className={classes.configContainer}>
              <Audio
                devicesSelection={devicesSelection}
                permissions={permissions}
              />
              <Settings
                mediaStream={mediaStream}
                devicesSelection={devicesSelection}
                setDevicesSelection={setDevicesSelection}
                permissions={permissions}
              />
            </div>
          </div>


        </ContentPreCall>
        <ActionsPreCall>
          <OrangeButton autoFocus onClick={ () => setShowPreCallTest(false) } >
            Apply Changes
          </OrangeButton>
        </ActionsPreCall>
      </Dialog>
  );
}
