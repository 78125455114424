import {makeStyles} from "@material-ui/core"

export default makeStyles({
  calendar: {
    width: "275px",
    position: "relatived",
  },

  calendarBody: {
    position: "absolute",
    left: "-120px",
  },

  calendarBodyShow: {
    display: "block",
  },

  calendarBobyHidden: {
    display: "none",
  },

  calendarHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  calendarButton: {
    width: "130px",
  },
});