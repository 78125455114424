import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({

  meter: {
    width: '430px',
    margin: '4px 20px 20px 0',
    '@media screen and (max-width:768px)': {
      margin: '4px 10px 10px 0px',
      width: '310px',
    },
    '@media screen and  (max-width:425px)': {
      margin: '4px 10px 10px 0',
      width: '250px'
    },
    '@media screen and  (max-width:375px)': {
      margin: '4px 10px 10px 0',
      width: '220px'
    },
  },

  instant: {
    color: '#0f9d58'
  },

  container: {
    display: 'inline-block',
    paddingTop: '0px',
  },

  output: {
    marginBottom: '20px'
  }

}));