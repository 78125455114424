import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    icons: {
        color: 'black',
    },
    title: {
      marginBottom: '25px'
    },
    titleContainer: {
      display: 'flex',
      alignItems: "center"
    },
    form: {
      margin: {
        margin: theme.spacing(1),
      },
    },
    formContainer: {
      height: '39px',
      borderRadius: '5px',
      marginLeft: 'auto'
    },
    pageNumbering: {
      margin: '0 3px 0 3px',
      cursor: 'pointer'
    },  
    pageNumberingContainer: {
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      marginTop: '25px'
    },
    noMeetings: {
      textAlign: 'center',
      padding: '15px 0 15px 0'
    },
    pageRefresh: {
      marginLeft: '48%',
      paddingTop: '25px',
      paddingBottom: '25px'
    },
    curentPageBold: {
      fontWeight: 600
    },
}));
