import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Paper, Typography, InputAdornment, IconButton, DialogContent, Dialog, CircularProgress, OutlinedInput, InputLabel } from '@material-ui/core';
import CustomAlert from '../../shared/components/CustomAlert';
import useStyles from '../../../styles/LoginStyle/LoginPage';
import useStore from '../../shared/store';
import { ThemeProvider } from '@material-ui/core/styles';
import apiConfigs from '../../shared/utilities/apiConfigs';
import { api } from '../../shared/utilities/api';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { customInputTheme } from '../../../styles/LoginStyle/LoginPage';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
// Components
import Header from './Header';
import GetReportFooter from '../../../frame/GetReportFooter';

export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false
  });
  const [error, setError] = useState("Invalid username or password. Try again");

  const classes = useStyles();
  const { setLogin, setOrg, setUser, setWhiteLabel } = useStore();
  const { whiteLabel } = useParams();

  useEffect(() => {
    if (whiteLabel) {
      validOrg();
    }
  }, []);

  const validOrg = async () => {
    const res = await api(apiConfigs.baseOption('get', 'appointments/getOrgProviders', { params: { org_id: whiteLabel, limit: 1 } }));
    if (res.status === 200) {
      setWhiteLabel(whiteLabel);
    } else {
      setError("Invalid Org or White label. Please try again.");
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    setLoading(true);

    const result = await api(
      apiConfigs.baseOption('POST', 'auth/login', { data: { "email": values.email, "password": values.password } })
    );

    switch (result.status) {
      case 200:
        const firebaseSuccess = await handleFirebaseLogin(result.data.firebaseToken);

        if (firebaseSuccess) {
          localStorage.setItem("token", result.data.token);
          setLogin(true);
          setOrg(result.data.default_org);
          setUser(result.data.user.document);
          setWhiteLabel(result.data.default_org.whiteLabelURL);
        } else {
          setOpenAlert(true);
        }
        break;
      case 400:
        setError("Invalid username or password. Try again");
        setOpen(true);
        break;
      default:
        setOpenAlert(true);
    }
    setLoading(false);
  };

  const handleFirebaseLogin = async (token) => {
    try {
      await firebase.auth().signInWithCustomToken(token);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return (
    <div className={classes.loginPage}>
      <Header />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        className={classes.loginBox}
      >
        <Paper className={classes.loginPaper} elevation={3}>
          <Typography className={classes.loginTitle} align='center' variant='h3'> Provider Access Portal </Typography>
          <form onSubmit={handleLogin}>
            <InputLabel className={classes.loginTextFieldLabel} htmlFor="password">Email</InputLabel>
            <ThemeProvider theme={customInputTheme}>
              <OutlinedInput
                id="email"
                fullWidth
                placeholder="Enter email"
                onChange={e => setValues({ ...values, email: e.target.value })}
                className={classes.loginTextField}
                classes={{ input: classes.loginTextFieldInner }}
              />
            </ThemeProvider>
            <InputLabel className={classes.loginTextFieldLabel} htmlFor="password">Password</InputLabel>
            <ThemeProvider theme={customInputTheme}>
              <OutlinedInput
                id="password"
                fullWidth
                placeholder="Enter password"
                type={values.showPassword ? 'text' : 'password'}
                onChange={e => setValues({ ...values, password: e.target.value })}
                className={classes.loginTextField}
                classes={{ input: classes.loginTextFieldInner }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </ThemeProvider>
            <Box display='flex' justifyContent='center'>
              {loading ?
                <CircularProgress style={{ color: '#1766b3' }} />
                :
                <Button type="submit" className={classes.loginButton} fullWidth color='primary'>Log In</Button>
              }
            </Box>
          </form>
        </Paper>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography variant='body1'>
            {error}
          </Typography>
        </DialogContent>
        <Button onClick={handleClose} style={{ margin: '0.5rem' }} variant='outlined' color='primary'>Ok</Button>
      </Dialog>
      <CustomAlert description="Login failed" type="error" openAlert={openAlert} setOpenAlert={setOpenAlert} />
      <GetReportFooter /> {/* Replace Footer component with GetReportFooter */}
    </div>
  );
}
