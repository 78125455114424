import React from 'react'
import {  Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { ReactComponent as WelcomeImage } from '../public/img/welcome.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({

  dialogContainer: {
    width: '360px',
    minHeight: '430px'
  },

  imageContainer: {
    width: 'auto',
    height: '250px',
    padding: '5px',
    marginTop: '10px'
  },

  header: {
    marginTop: '2%',
    paddingBottom: '0',
    "& h2" : {
      fontWeight: 600
    }
  },

  subtext: {
    fontSize: '1em',
    marginTop: '10px'
  },
 
}));

export default function StatusUpdate({ header, message, closeAction }) {

  const classes = useStyles();

  return (
    <Dialog
        open={true}
        classes={{ paper: classes.dialogContainer}}
        // disableBackdropClick={true}
        onClose={closeAction}
      >
      <WelcomeImage className={classes.imageContainer}/>
      <DialogTitle className={classes.header}>{header}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.subtext}>
          {message}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
