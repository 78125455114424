import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({

  dialogPaper: {
    maxHeight: '900px',
    maxWidth: '950px',
    '@media screen and  (max-width: 885px) ': 
      {
        width: '47%',
        height: '95%',
     },
     '@media screen and  (max-width: 768px) ': 
      {
        width: '55%',
        height: '97%', paddingRight:'0px',
     },
      
      '@media screen and  (max-width: 425px) ': 
      {
        width: '83%',
        height: '92%',
        
      },
      '@media screen and  (max-width: 375px) ': 
      {
        width: '90%',
        height: '85%',
        marginright: '1%'
        
      },
      '@media screen and  (max-width: 320px) ': 
      {
        width: '82%',
        height: '73%',
        marginright: '1%'
        
      }
      
    
  },

  configContainer: {
    margin: '0 20px 0 20px',
    
  },

  container: {
    display: 'flex',
    '@media screen and (max-width:885px)': {
      flexDirection: 'column',
      
      
     
    },
   
  }
}));