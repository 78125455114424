import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    Divider,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

import React, { useEffect, useState } from "react";
import settingsPageStyles from "../../../../../styles/DialogStyle/components/settingsPageStyles";
import { getClinicOption, updateClinicAnnouncement, getDoctorOption, updateDoctorAnnouncement } from "../../../../shared/utilities/firebaseUtil";
import useStore from "../../../../shared/store";

const MAX_LENGTH = 300;

const Announcements = () => {
    const classes = settingsPageStyles();

    const [isEditing, setIsEditing] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [announcement, setAnnouncement] = useState({ title: '', content: '' });
    const [fetchedAnnouncement, setFetchedAnnouncement] = useState({ title: '', content: '' });

    const { user, whiteLabel } = useStore();

    const isDoctor = user.roles[0] === 'doctor';

    const handleChangeText = (field, value) => {
        const newAnnouncement = { ...announcement, [field]: value };

        setAnnouncement(newAnnouncement);
    };

    const handleSaveChanges = async () => {
        setIsLoading(true);
        isDoctor 
            ? await updateDoctorAnnouncement(user._id, announcement)
            : await updateClinicAnnouncement(whiteLabel, announcement);
        setIsLoading(false);
        setIsEditing(false);
        setFetchedAnnouncement(announcement);
    };

    const handleCancel = () => {
        setAnnouncement({...fetchedAnnouncement});
        setIsEditing(false);
    };

    const getClinicSavedOptions = async () => {
        const announcement = isDoctor 
            ? (await getDoctorOption(user._id)).announcement
            : (await getClinicOption(whiteLabel)).announcement;

        if(announcement) {
            setAnnouncement(announcement);
            setFetchedAnnouncement(announcement);
        }
    };

    useEffect(() => {
        getClinicSavedOptions();
    }, [whiteLabel, user._id]);

    const rootClassNames = clsx({
        [classes.rootExpanded]: isExpanded,
        [classes.root]: !isExpanded,
    });

    const labelClassNames = clsx({
        [classes.labelExpanded]: isExpanded,
        [classes.labelCollapsed]: !isExpanded,
    });

    return (
        <Grid
            container
            component={Paper}
            elevation={3}
            className={rootClassNames}
        >
            <Grid item xs={12} className={classes.paperComponment}>
                <Box className={classes.labelWithEdit} onClick={() => setIsExpanded(!isExpanded)}>
                    <Typography className={labelClassNames}>
                        Announcement
                    </Typography>
                    <Typography className={classes.edit}>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: isExpanded,
                            })}
                            aria-expanded={isExpanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <Grid container>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <Grid
                                container
                                className={
                                    !isEditing ? classes.disabledBackground : ""
                                }
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.dividerBorder}
                                >
                                    <Typography
                                        variant="body2"
                                        className={classes.labelText}
                                    >
                                        Title
                                    </Typography>
                                    <TextField
                                        InputProps={{ disableUnderline: true }}
                                        variant="outlined"
                                        fullWidth
                                        className={classes.textBox}
                                        placeholder="Enter Title"
                                        name="title"
                                        value={announcement.title}
                                        onChange={(e) =>
                                            handleChangeText(
                                                "title",
                                                e.target.value
                                            )
                                        }
                                        size="small"
                                    />
                                    <Typography
                                        variant="body2"
                                        className={classes.labelText}
                                    >
                                        Content
                                    </Typography>
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        name="content"
                                        rows={4}
                                        onChange={(e) =>
                                            handleChangeText(
                                                "content",
                                                e.target.value
                                            )
                                        }
                                        value={announcement.content}
                                        variant="outlined"
                                        className={classes.textBox}
                                        placeholder="Enter Message"
                                    />
                                    <div className={classes.textCounter}>
                                        {announcement.content
                                            ? announcement.content.length
                                            : 0}
                                        /{MAX_LENGTH}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            {isEditing ? (
                                <Box>
                                    {isLoading ? (
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                        >
                                            <CircularProgress className={ classes.loadingCircle }/>
                                        </Box>
                                    ) : (
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            className={classes.saveBtnGroup}
                                        >
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={handleSaveChanges}
                                            >
                                                Save Changes
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                className={
                                                    classes.saveChangesBtn
                                                }
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            ) : (
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    className={classes.saveBtnGroup}
                                >
                                    <Button
                                        xs={12}
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => setIsEditing(true)}
                                    >
                                        Edit
                                    </Button>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    );
};

export default Announcements;
