import React from "react";
import PortonLogoImg from "../img/Porton_Default.png"; // Import the image
import useStyles from "../styles/NewFooterStyle";

export default function ReportFooter() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <div className="container">
        <img
          src={PortonLogoImg}
          alt="Porton Logo"
          className={classes.portonImgFooter}
        />
        <div className={classes.footerText}>
          <ul className={classes.linkList}>
            <li className={classes.listItem}>
              <a href="https://www.portonhealth.com/terms-and-conditions/">
                <b>
                  <u>Terms of Service</u>
                </b>
              </a>
            </li>
            <li className={classes.listItem}>
              <a href="https://www.portonhealth.com/faq/">
                <b>
                  <u>FAQ</u>
                </b>
              </a>
            </li>
            <li className={classes.listItem}>
              <a href="https://www.portonhealth.com/privacy-and-security/">
                <b>
                  <u>Privacy and Security</u>
                </b>
              </a>
            </li>
          </ul>
          <medium className="copyright">
            © {new Date().getFullYear()} Porton Health
          </medium>
        </div>
      </div>
    </footer>
  );
}
