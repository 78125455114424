import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  rowContent: {
     
    padding: '3px 0 3px 4px',
    '@media screen and (max-width:960px)': {
      maxWidth: '480px'
    },
 
    
    '@media screen and (max-width:425px)': {
      maxWidth: '300px'
    },
    '@media screen and (max-width:375px)': {
      maxWidth: '260px'
    },
   
  },

  title: {
    marginBottom: '15px'
  },
 

}));