import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tableContainerHeader: {
    textAlign: 'left',
    paddingLeft: '8%',
    fontWeight: 600,
    fontSize: '16px',
    height: '80px',
    width: '99.99%',
    margin: 'auto',
  },
  tableContaineBodyWhite: {
    textAlign: 'left',
    paddingLeft: '8%',
    fontSize: '16px',
    height: '80px',
    width: '99.99%',
    margin: 'auto',
  },
  tableContaineBodyColor: {
    textAlign: 'left',
    paddingLeft: '8%',
    fontSize: '16px',
    height: '80px',
    width: '99.99%',
    margin: 'auto',
    background: '#F5F6FA' 
  }
}));