/**
 * Global state mangement
 */

import create from 'zustand';

const useStore = create(set => ({
    
    login: false,
    setLogin: (login) => set({ login }),
    
    org: {},
    setOrg: (org) => set({ org }),
    
    user: {},
    setUser: (user) => set({ user }),

    tempUser: 'Dr. Reyes',

    whiteLabel: '',
    setWhiteLabel: (state) => set({ whiteLabel: state }),

    envVars: {},
    setEnvVars: (envVars) => set({envVars}),

    // Constants for Different Package
    SIMPLE_PACKAGE:1,
    SOLO_PACKAGE:2,
    CLINIC_PACKAGE:3,

    // Constant for provider status
    PROVIDER_STATUS : {PROVIDER_AVAILABLE:0, PROVIDER_AWAY:1, PROVIDER_UNAVAILABLE:2},

    // Default Package will be SIMPLE_PACKAGE
    currentPackage: 2,

    /* TODO: setCurrentPackage for each
         -  CurrentPackage should be stored in firebase
         -  setCurrentPackage When user login and receive CurrentPackage from firebase server*/
    setCurrentPackage: (currentPackage) =>set({currentPackage}),
}));

export default useStore;