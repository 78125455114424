import React from 'react'
import { Typography, Paper } from '@material-ui/core'
import useStyles from '../../../styles/LoginStyle/InfoForm';
// Components
import Header from './Header';
import Footer from './Footer';

export default function TermsAndConditions() {
  const classes = useStyles();
  return (
    <>
    <Header/>
    <Paper className={classes.container} elevation={3}>
    <Typography variant="h3" className={classes.header}>Terms & Conditions</Typography>
      <div className={classes.content}>
        <h2 className={classes.contentHeader}>Effective date: March 6, 2017</h2>
        <p>Thank you for visiting http://www.portonhealth.com/ (this “Site”). Carefully review these Terms of Use (“Terms”) prior to using this Site. By using and or visiting this Site, you consent to both these Terms and the terms and conditions of Portonhealth.com’s Privacy Policy, published at https://www.portonhealth.com/privacy-policy, and which is incorporated herein by reference. If you do not agree to these Terms, do not use this Site.</p>

        <h3 className={classes.contentSubHeader}>General</h3>
        <p>The Site is owned and operated by PORTON Health, Corp., (the “Company”). The Company reserves the right to revise or remove any part of the Terms in its sole discretion at any time and without prior notice to you by updating this posting. You thus should visit this page periodically for changes. This Site is continually under development and changes to this Site may be made at any time. Any changes to the Terms are effective upon posting to this Site. If you disagree with the Terms, your sole remedy is to discontinue your use of this Site. Your continued use after a change has been posted constitutes your acceptance of the changes.</p>

        <h3 className={classes.contentSubHeader}>Consultative Service</h3>
        <p>The purpose of the service provided through the Site is to give physicians and their patients access to additional information which they may or may not choose to utilize in planning medical care. It is not a substitute for primary care and no physician-patient relationship is created by use of this Site. The service provided through the Site varies by condition and by state of residence of the patient, with special emphasis on providing a second opinion (sometimes referred to as a consulting opinion or an expert opinion) regarding diagnosis and treatment options or providing lists of local medical professionals who may be accepting new patients.</p>
        <p>The service is limited. In some cases, the online nature of the service will make it difficult to verify a diagnosis (particularly with conditions that rely heavily on direct physician-patient interaction for accurate diagnosis). In those cases, the service provided through the Site will focus on the assessment or recommendation of treatment options. The decision to focus on diagnosis, treatment recommendation, or both rests with the consulting physician.</p>
        <p>The service provided through this Site differs from the diagnostic services typically provided by a physician. The medical professionals providing services through this Site will not have the benefit of information that would be obtained by examining you in person and observing your physical condition. Therefore, the medical professional providing services through this Site may not be aware of facts or information that would affect his or her opinion of your diagnosis. To reduce the risk to you of this limitation, the Company strongly encourages you to discuss the second opinion with your physician. By deciding to engage the services of this Site, you acknowledge and agree that you are aware of this limitation and agree to assume the risk of this limitation. Furthermore, you agree and accept that: (i) the diagnosis you will receive is limited and provisional; (ii) the second opinion is not intended to replace a full medical evaluation or an in-person visit with a physician; (iii) the medical professionals providing services through this Site do not have important information that is usually obtained through a physical examination; and (iv) the absence of a physical examination may affect the medical professional’s ability to diagnose your condition, disease or injury.</p>

        <h3 className={classes.contentSubHeader}>Company</h3>
        <p>By requesting any service through the Site, you authorize us to collect your medical records on your behalf from any and all facilities that may have such records, store those records, and transmit them to the physician or physicians appropriate for your case.</p>

        <h3 className={classes.contentSubHeader}>Privacy</h3>
        <p>We are constantly seeking to improve the quality of care. If you have requested a consultative service, a physician referral, or another service, we may at any time review your case, your medical records from prior to and after your interaction with the Site, and any records created as a result of services received. We may request courtesy notices from your treating physician(s) and/or request additional medical records from them, including records pertaining to care you received after receiving the services. We may review these records for, among other purposes, reviewing the quality of service you received, reviewing the quality of service provided by the physicians and medical professionals treating you (including the physicians and medical professionals to whom you may have received a referral), to better understand the course of treatment for your condition(s) including information regarding outcomes and costs, and to improve treatments and recommendations for people with similar or different conditions. We will take care to minimize personally identifying information in this process. We may also use anonymous information gathered, including information from your medical records, to generate conclusions about the healthcare process, particular conditions, and other matters. We and our affiliated physicians and researchers may publish this anonymous information in journals, websites and other locations. However, we will not publish your name or any identifying information about you; we will use only anonymous data for any public purpose.</p>
        <p>We may be required by law to turn over certain information to law enforcement, or to respond to civil subpoenas and other legal process. By using this Site, you acknowledge such risks. Additionally, we may voluntarily provide information to law enforcement or investigative agencies regarding suspected disruptive or destructive uses of the Site, such as “Denial-of-Service” attacks, credit card fraud, and other matters.</p>

        <h3 className={classes.contentSubHeader}>Registration</h3>
        <p>You are not obligated to register with the Company in order to access this Site. However, certain services of this Site are available only to users who have registered with the Company or other persons affiliated with the Company who have been granted user IDs and passwords (“Secure Users”). If you are a Secure User, you agree to accurately maintain and update any information about yourself that you have provided to the Company. If you do not maintain such information, or the Company has reasonable grounds to suspect as much, the Company has the right to suspend or terminate your account and your use of this Site. You may have as many active Secure User accounts on this Site as you may reasonably choose at any given time and only you may use your designated accounts to access the Site. You also agree to promptly notify the Company of any unauthorized use of your username, password or any other breach of security that you become aware of involving or relating to this Site by emailing the Company at support@portonhealth.com. In addition, you agree to exit from your Secure User account at the end of each session. The Company explicitly disclaims liability for any and all losses and damages arising from your failure to comply with this section.</p>

        <h3 className={classes.contentSubHeader}>Site Security</h3>
        <p>Our Site requires users to create unique identifiers (such as a username and password) in order to log into many areas of the Site. We utilize these unique identifiers to verify the user’s identity and eligibility, in order to protect our members from the release of sensitive or personally identifiable information to unauthorized users. To help protect the privacy of data you transmit through this Site, where personally identifiable information is requested, we also use technology designed to encrypt the information that you input before it is sent to us using Secure Sockets Layer (SSL) technology or similar encryption technology. In addition, Company takes steps to protect the user data we collect against unauthorized access. However, you should keep in mind that this Site and our services are run on software, hardware and networks, any component of which may, from time to time, require maintenance or experience problems or breaches of security beyond our control.</p>
        <p>You must exercise caution, good sense and sound judgment in using this Site. You are prohibited from violating, or attempting to violate, the security of this Site. Any such violations may result in criminal and/or civil penalties against you. The Company may investigate any alleged or suspected violations and if a criminal violation is suspected, the Company will cooperate with law enforcement agencies in their investigations.</p>

        <h3 className={classes.contentSubHeader}>Credit Transactions</h3>
        <p>The Company does not accept payment in the form of cash, check, money order or insurance reimbursement. Major credit cards (MasterCard, Visa, American Express and Discover) are the only accepted means of payment. When you pay by credit card, you authorize the Company to bill and charge the credit card indicated in your order for any fees and any other amounts provided for in your order as the same become due and payable. In the event that your credit card expires or the Company is otherwise unable to debit the applicable amounts from your credit card, the Company shall provide notice to you, whereupon you shall immediately furnish the Company with a valid credit card account number. Your request for services from this Site will not be fulfilled until full payment has been received and verified.</p>

        <h3 className={classes.contentSubHeader}>Other Prohibited Activities</h3>
        <p>In using this Site or any services provided by PORTON Health, you agree not to:</p>
        <ul>
            <li>Send or otherwise transmit to or through this Site, any unlawful, infringing, harmful, harassing, defamatory, threatening, hateful or otherwise objectionable material of any kind, any material that can cause harm or delay to this Site or computers of any kind, and any unsolicited advertising, solicitation or promotional materials;</li>
            <li>Misrepresent your identity or affiliation in any way;</li>
            <li>Restrict or inhibit any person from using this Site, disclose personal information obtained from this Site or collect information about users of this Site;</li>
            <li>Reverse engineer, disassemble or decompile any section or technology on this Site, or attempt to do any of the foregoing;</li>
            <li>Gain unauthorized access to this Site, to other users’ accounts, names, personally identifiable information or other information, or to other computers or websites connected or linked to this Site;</li>
            <li>Launch or use any automated system, including without limitation, “robots,” “spiders,” or “offline readers,” that access this Site in a manner that sends more request messages to our servers in a given period of time than a human can reasonably produce in the same period by using a conventional web browser;</li>
            <li>Send or otherwise transmit to or through this Site chain letters, unsolicited messages, so-called “spamming” or “phishing” messages, or messages marketing or advertising goods and services;</li>
            <li>Post, transmit or otherwise make available any virus, worm, spyware or any other computer code, file or program that may or is intended to damage or hijack the operation of any hardware, software or telecommunications equipment;</li>
            <li>Violate any applicable laws or regulations or these Terms;</li>
            <li>Use abusive, harassing or threatening language or make abusive, harassing, bullying or threatening communications toward any staff member, any physician, or any other user of the Site;</li>
            <li>Create a hostile environment for any staff member, any physician, or any other user of the Site based on such person’s race, age, national origin, disability, gender or reassignment thereof, religion or belief, sex, sexual orientation, marriage/partnership status, or pregnancy/maternity.</li>
            <li>Alter or modify any part of the Materials or services offered on this Site; or</li>
            <li>Assist or permit any persons in engaging in any of the activities described above.</li>
        </ul>
        <p>Any of the foregoing may result in actions including but not limited to termination of your access to the Site or any services provided by PORTON Health.</p>

        <h3 className={classes.contentSubHeader}>Our Intellectual Property Rights</h3>
        <p>The content on this Site, including without limitation, any text, software, graphics, photos, sounds, music, videos, audio-visual combinations, patents, interactive features, any trademarks, service marks and logos contained therein and any other materials you may view on, access through, or contribute to this Site (collectively “Materials”) are owned by or licensed to the Company, subject to copyright, trademark and other intellectual property rights under Canada and foreign laws and international conventions.</p>
        <p>No Materials from this Site may be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way without written permission of the rights owner. You agree not to circumvent, disable, or otherwise interfere with security related features of this Site or features that prevent or restrict use or copying of any Materials or content.</p>
        <p>Modification of any Materials or use of any Materials for any other purpose is a violation of the copyrights and other proprietary rights of the Company, or of other entities or persons where so indicated, unless the Company or the owner has provided said Material for such express purpose. Permission for all other uses of Materials contained herein, including reproducing and distributing multiple copies or using Materials on any other website or networked computer or linking to any secured or private page at this Site must be obtained from the Company or the appropriate rights owner in advance; otherwise, such use is prohibited. Requests for such authorization from the Company should be submitted via an email to support@portonhealth.com. All design rights, databases and compilation and other intellectual property rights associated with this Site, in each case whether registered or unregistered, and related goodwill, are proprietary to the Company.</p>

        <h3 className={classes.contentSubHeader}>Links to Other Websites</h3>
        <p>As you view this Site, you may see links to third-party websites. These links are for convenience only. If you use these links, you will leave this Site. Certain of these linked websites may make use of the Company’s proprietary intellectual property rights (such as copyrights, trademarks, service marks, logos and trade names) under license from the Company. The Company is not responsible for the availability or content of these other websites or for any viruses or other damaging elements encountered in linking to a third-party website. In addition, providing links to these websites should not be interpreted as endorsement or approval by the Company of the organizations sponsoring such third-party websites or their products or services. These Terms do not apply to any other websites.</p>

        <h3 className={classes.contentSubHeader}>Third Party Interactions</h3>
        <p>Your interactions with entities or individuals found on or through this Site or the services provided through the Site, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such entities or individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any third party. You are solely responsible for, and will exercise caution, discretion, common sense and judgment in, using this Site and disclosing personal information.</p>
        <p>You agree that the Company shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings. If there is a dispute between you and any third party, you understand and agree that the Company is under no obligation to become involved.</p>

        <h3 className={classes.contentSubHeader}>Jurisdictional Issues</h3>
        <p>This Site is controlled and operated by the Company from our offices within British Columbia, Canada. Those who choose to access this Site from other locations do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable. Access to this Site from jurisdictions where the contents of this Site are illegal or penalized is prohibited.</p>

        <h3 className={classes.contentSubHeader}>Termination</h3>
        <p>The Company may terminate your use of this Site or any of our features or services at any time and for any reason without notice for conduct violating these Terms. The Company will terminate a user’s access to this Site and its features and services if, under appropriate circumstances, the user is determined to be a repeat infringer of third party rights or in breach of the Terms. Upon any such termination, you must destroy all Materials obtained from this Site and all copies thereof. The provisions of these Terms concerning Site security, prohibited activities, copyrights, trademarks, disclaimer, limitation of liability, indemnity and jurisdictional issues shall survive any such termination. You agree that if your use of this Site is terminated pursuant to these Terms, you will not attempt to use this Site under any name, real or assumed, and further agree that if you violate this restriction after being terminated, you will indemnify and hold the Company harmless from any and all liability that the Company may incur therefore.</p>

        <h3 className={classes.contentSubHeader}>Disclaimer</h3>
        <p>The Materials in this Site are provided “as is” and without warranties of any kind, either express or implied. To the fullest extent permissible pursuant to applicable law, the Company disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. The Company does not warrant that the functions contained in the materials will be uninterrupted or error-free, that defects will be corrected or that this Site or the server that makes it available are free of viruses or other harmful components. The Company does not make any representations or warranties regarding the use or the results of the use of the Materials in this Site in terms of their correctness, accuracy, reliability or otherwise. Applicable law may not allow the exclusion of implied warranties, so the above exclusion may not apply to you.</p>

        <h3 className={classes.contentSubHeader}>Limitation of Liability</h3>
        <p>To the extent permitted under applicable law, under no circumstances, including, but not limited to, negligence, shall the company or your payer / employer / healthcare group sponsor / health plan be liable for any compensatory, punitive, special or consequential damages that result from the use of, or the inability to use, this site or the materials on it, even if the company or an authorized representative of the company has been advised of the possibility of such damages. Applicable law may not allow the limitation or exclusion of liability or incidental or consequential damages, so the above exclusion may not apply to you.</p>

        <h3 className={classes.contentSubHeader}>Indemnity</h3>
        <p>You agree to indemnify, defend and hold the Company and its directors, officers, employees, agents and contractors harmless from and against any and all claims, damages, losses, costs (including without limitation reasonable attorneys’ fees) or other expenses that arise directly or indirectly out of or from (i) your breach of any provision of these Terms, or (ii) your activities in connection with this Site.</p>

        <h3 className={classes.contentSubHeader}>Children</h3>
        <p>This Site is not intended for children under the age of 13. If you are under 13 years of age, please do not use or access this Site at any time or in any manner. By using this Site, you affirm that you are over the age of 13. The Company does not seek through this website to gather personal information from or about persons under the age of 13 without the consent of a parent or guardian.</p>

        <h3 className={classes.contentSubHeader}>Other</h3>
        <p>By choosing to visit this Site or otherwise provide information to the Company, you agree that any dispute over your use of this Site or these Terms will be governed by the laws of the Province of British Columbia and Canada. You also consent to the adjudication of any disputes arising in connection with our Site in the Province and federal courts located in Canada. You also agree to attempt to mediate any such dispute and to abide by all limitations of liability contained herein.</p>
        <p>If any provision of these Terms is unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions.</p>
        <p>If you have any questions or complaints regarding these Terms, please submit your questions or complaints to support@portonhealth.com. The Company will endeavor to respond to you promptly.</p>
      </div>
    </Paper>
    <Footer/>
    </>
  )
}
