import React, { useState, useEffect } from 'react';
import Log from './components/Log'
import DropDownCalendar from './components/DropDownCalendar'
import { Paper, Typography, IconButton, FormControl, CircularProgress, Box} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useStyles from '../../styles/MeetingHistoryStyle/index';
import { PlainButton } from '../../styles/CustomButtons';
import apiConfigs from '../shared/utilities/apiConfigs';
import { api } from '../shared/utilities/api';
import useStore from '../shared/store';
import moment from 'moment';
import clsx from 'clsx';
import HumburgerButton from "../shared/HumburgerButton";

const MeetingHistory = ({ smallScreenView, handleMenuClick }) => {

  const [monthYear, setMonthYear] = useState(`${moment().format('M')}:${moment().format('Y')}`);
  const [datesDropDown, setDatesDropDown] = useState([]);
  const [noMeetings, setNoMeetings] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(null);
  const [pageList, setPageList] = useState([]); 
  const [meetings, setMeetings] = useState([]);
  const [window, setWindow] = useState({left: null, right: null})
  
  const classes = useStyles();
  const { user } = useStore();
  const pageSize = 7;

  let userAgentString = navigator.userAgent;

  useEffect(() => {
    // By defalt grab first page of meeting history
    const getInitialMeetings = async (newMonth, newYear) => {
      const result = await api(
        apiConfigs.baseOption(
          'GET', 
          `appointments/meetingHistory/${user._id}?month=${newMonth}&year=${newYear}&page=${0}`, 
          {headers: {"Content-Type": 'application/json'}}
        ));
        

        if (result.status === 200) {
          if (!result.data.totalMeetings) {
            setNoMeetings(true);
            setLoading(false);
            setPageList([]);
          } else {
            setCurrentPage(1);
            const totalPages = Math.ceil(result.data.totalMeetings / pageSize);
            const tempPageList = [];

            for (let page = 1; page <= totalPages; page++) {
              tempPageList.push(page);
            }

            // We set the window (1 ... 2 3 4 ...) totalPages length of 3
            if (totalPages > 6) {
              setWindow({left: 1, right: 3});
            }

            setPageList(tempPageList);
            setMeetings(result.data.meetings);
            setNoMeetings(false);
            setLoading(false);
          }
        }
    }
    if (user._id) {
      getInitialMeetings(monthYear.split(':')[0], monthYear.split(':')[1]);
    }
    
  }, [user, monthYear]);

  useEffect(() => {
    // Populate month and year since account created or since app went live
    const dates = [];
    const endDate = moment();
     // Fri Oct 08 2021 10:16:03 GMT-0700 (Pacific Daylight Time)
    // 
    //const d = new Date(2021,1,11,10, 33, 30, 0)
    //let startDate = moment(d);
    //console.log(user.createdAt)

    let startDate = moment(user.createdAt);
    if ((parseInt(startDate.format('Y')) <= 2021) && (parseInt(startDate.format('M')) < 4)) {
      startDate = moment('2021-03-31 00:00:00.000')
    }

    // If the user account existed before the webapp goes into production, do not show these dates
    while (endDate.diff(startDate, 'months') >= 0) {
      // for firefox we have to add the months the other way around
      if (userAgentString.indexOf("Firefox") > -1 ) {
        dates.push({ month: startDate.format('M'), year: startDate.format('Y') });
        startDate.add(1, 'month')
      } else { 
      dates.unshift({ month: startDate.format('M'), year: startDate.format('Y') });
      startDate.add(1, 'month')
       }
    }

    dates.forEach((date, i) => {
      dates[i] = {...date, monthText: moment().month(date.month - 1).format("MMMM")}
    })

    setDatesDropDown(dates);
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    // A new motth has been selected or a new page has been selected
    const getInitialMeetings = async (id, month, year, page) => {
      setLoading(true);
      const result = await api(
        apiConfigs.baseOption(
          'GET', 
          `appointments/meetingHistory/${id}?month=${month}&year=${year}&page=${page - 1}`, 
          {headers: {"Content-Type": 'application/json'}}
        ));

        
      if (result.status === 200) {
        setMeetings(result.data.meetings);
        setLoading(false);
      } else {
        // Do error handling
      }
    }

    const id = user._id;
    const month = monthYear.split(':')[0];
    const year = monthYear.split(':')[1];
    const page = currentPage;

    if (page) {
      getInitialMeetings(id, month, year, page);
    }
    // eslint-disable-next-line
  }, [currentPage, user]);

  // const handleChange = (event) => {
  //   setMonthYear(event.target.value);
  // };

  const handleChange = (date) => {
    setMonthYear(`${date.month}:${date.year}`);
  };

  const nextPageRight = () => {

    if (currentPage < pageList.length) {
      setCurrentPage(currentPage => currentPage + 1);

      if (currentPage + 1 === window.right + 1) {
        setWindow({ left: window.left + 1, right: window.right + 1 })
      }
    }
  };

  const nextPageLeft = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage => currentPage - 1);

      if (currentPage - 1 === window.left - 1) {
        setWindow({ left: window.left - 1, right: window.right - 1 })
      }
    }
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const nextMonthLeft = () => {
    datesDropDown.forEach((date, index) => {
      if ((date.month === monthYear.split(":")[0]) && (date.year === monthYear.split(":")[1])) {
        if (index < datesDropDown.length - 1) {
          setMonthYear(`${datesDropDown[index + 1].month}:${datesDropDown[index + 1].year}`)
        }
      }
    });
  };

  const nextMonthRight = () => {
    datesDropDown.forEach((date, index) => {
      if ((date.month === monthYear.split(":")[0]) && (date.year === monthYear.split(":")[1])) {
        if (index > 0) {
          setMonthYear(`${datesDropDown[index - 1].month}:${datesDropDown[index - 1].year}`)
        }
      }
    });
  };

  return (
      <div>
       <HumburgerButton smallScreenView={smallScreenView} handleMenuClick={handleMenuClick} />
        <Typography variant='h3' className={classes.title}>Meeting History</Typography>
          <div className={clsx(classes.title, classes.titleContainer)}>
            <Typography variant='h3'>{`${moment(monthYear.split(':')[0], 'M').format('MMMM')} ${monthYear.split(':')[1]}`}</Typography>
            <IconButton size='small' component="span" className={classes.icons} onClick={() => nextMonthLeft()}>
              <ChevronLeftIcon fontSize='large' />
            </IconButton>
            <IconButton size='small' component="span" className={classes.icons} onClick={() => nextMonthRight()}>
              <ChevronRightIcon fontSize='large' />
            </IconButton>

            <Paper elevation={2} className={classes.formContainer}>
              <FormControl className={classes.form}>
                {/*<NativeSelect
                  value={monthYear}
                  onChange={handleChange}
                  input={<Dropdown />}
                >
                {
                  datesDropDown.map((date, index) => (
                    <option key={index} value={`${date.month}:${date.year}`}>{`${date.monthText.toUpperCase()} ${date.year}`}</option>
                  ))
                }
                </NativeSelect>*/}

                <DropDownCalendar dates={datesDropDown} onChange={handleChange} initial={monthYear}/>
              </FormControl>
            </Paper>
          </div>
        <Paper elevation={2}>
          { 
            loading ? <Box className={classes.pageRefresh}><CircularProgress/></Box> :
            noMeetings ? <Typography className={classes.noMeetings} variant='body1'> You have no past meetings for this month. </Typography> : 
            <Log meetings={meetings} />
          }
        </Paper>

        <div className={classes.pageNumberingContainer}>

          <PlainButton
            startIcon={<ChevronLeftIcon fontSize='large' />}
            onClick={() => nextPageLeft()}
          >
            Prev
          </PlainButton>

          {!loading && currentPage && 
            <div style={{display: 'inline'}}>
            {
              // eslint-disable-next-line
              pageList.map((page, i) => {
                if (window.left && window.right) {

                  if (page >= window.left && page <= window.right) {
                    if (page === window.left && page >= 3) {
                      return (
                        <span> 
                          <span> {'...'} </span>
                          <span key={i} onClick={() => goToPage(page)} className={page === currentPage ? clsx(classes.pageNumbering, classes.curentPageBold): classes.pageNumbering}>
                          {page}
                          </span>
                        </span>
                      )
                    } else if (page === window.right && page <= pageList.length - 2) {
                      return (
                        <span> 
                          <span key={i} onClick={() => goToPage(page)} className={page === currentPage ? clsx(classes.pageNumbering, classes.curentPageBold): classes.pageNumbering}>
                          {page}
                          </span>
                          <span> {'...'} </span>
                        </span>
                      )
                    } else {
                      return <span key={i} onClick={() => goToPage(page)} className={page === currentPage ? clsx(classes.pageNumbering, classes.curentPageBold): classes.pageNumbering}>
                        {page}
                      </span>
                    }
                  } else {
                    if (page === 1) {
                      return <span key={i} onClick={() => goToPage(page)} className={page === currentPage ? clsx(classes.pageNumbering, classes.curentPageBold): classes.pageNumbering}>
                        {page}
                      </span>
                    }
                    if (page === pageList.length) {
                      return <span key={i} onClick={() => goToPage(page)} className={page === currentPage ? clsx(classes.pageNumbering, classes.curentPageBold): classes.pageNumbering}>
                        {page}
                      </span>
                    }
                  }

                } else {
                  return <span key={i} onClick={() => goToPage(page)} className={page === currentPage ? clsx(classes.pageNumbering, classes.curentPageBold): classes.pageNumbering}>
                    {page}
                  </span>
                }
              })
            }
            </div>
          }
          <PlainButton
            endIcon={<ChevronRightIcon fontSize='large' />}
            onClick={() => nextPageRight()}
          >
            Next
          </PlainButton>
        </div>
      </div>
  );
}
export default MeetingHistory;