import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    TextField,
    Typography,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useState } from "react";
import { api } from "../shared/utilities/api";
import apiConfigs from "../shared/utilities/apiConfigs";
import ReportFooter from "../../frame/GetReportFooter";



const MAX_FILE_COUNT = 5;

const FileUploader = ({ orgName, hashedId, sessionId, hin, orgId, userId }) => {
    const [loading, setLoading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [files, setFiles] = useState([]);

    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        const totalFiles = selectedFiles.length + files.length; // Check total files

        const validFileTypes = [
            "image/jpeg",
            "image/png",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "text/plain",
            "application/rtf",
        ];

        if (!selectedFiles || selectedFiles.length === 0) {
            alert("Please select your files to upload.");
            return;
        }

        if (totalFiles > MAX_FILE_COUNT) {
            alert(`You can only upload a total of ${MAX_FILE_COUNT} files.`);
            return;
        }

        let allFilesAreValid = true;
        for (let i = 0; i < selectedFiles.length; i++) {
            const selectedFile = selectedFiles[i];
            const fileType = selectedFile.type;
            const fileExtension = selectedFile.name
                .split(".")
                .pop()
                .toLowerCase();

            if (
                !(validFileTypes.includes(fileType) && fileExtension !== "rtf")
            ) {
                allFilesAreValid = false;
                break;
            }
        }

        if (!allFilesAreValid) {
            alert(
                "Invalid file type. Please upload a JPEG, PNG, PDF, Word document, or RTF file."
            );
            return;
        }

        let hasDuplicateFiles = false;
        for (let i = 0; i < selectedFiles.length; i++) {
            const selectedFile = selectedFiles[i];
            if (files.some((file) => file.name === selectedFile.name)) {
                hasDuplicateFiles = true;
                break;
            }
        }

        if (hasDuplicateFiles) {
            alert("Duplicate files are selected.");
            return;
        }

        for (let i = 0; i < selectedFiles.length; i++) {
            const selectedFile = selectedFiles[i];
            setFiles((curr) => {
                return [
                    ...curr,
                    { file: selectedFile, name: selectedFile.name },
                ];
            });
        }
    };

    const handleDelete = (targetIndex) => {
        const removedArray = files.filter(
            (_item, index) => index !== targetIndex
        );
        setFiles(removedArray);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!files.length) {
            alert("Please upload a file before submitting.");
            return;
        }

        const confirmation = window.confirm(
            "Are you sure you want to share these files?"
        );
        if (!confirmation) return;

        setLoading(true);

        try {
            let base64Files = [];
            const base64Promises = files.map((file) => {
                return toBase64(file.file);
            });
            await Promise.all(base64Promises).then((b64Files) => {
                base64Files = b64Files;
            });
            const uploadPromises = files.map((file, index) => {
                const base64File = base64Files[index];
                const fileData = {
                    orgName,
                    orgId,
                    userId,
                    hashedId,
                    sessionId,
                    hin,
                    file: {
                        name: file.name,
                        document: base64File,
                    },
                };

                return api(
                    apiConfigs.baseOption(
                        "POST",
                        "file/uploadFileToS3WithUrl",
                        {
                            data: fileData,
                        }
                    )
                );
            });
            await Promise.all(uploadPromises);

            setIsUploaded(true);
        } catch (error) {
            console.error("Error during file upload:", error);
            alert("Failed to upload the file. Please try again later.");
        }

        setLoading(false);
    };

    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => {
                console.error("File reading error:", error);
                reject("Error reading file. Please try again.");
            };
        });
    };

    return (
        <>
            <Card
                sx={{
                    maxWidth: "90%",
                    margin: "auto",
                    mt: 5,
                    padding: 3,
                    boxShadow: 5,
                }}
            >
                {isUploaded ? (
                    <CardContent
                        style={{
                            padding: "20px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                            textAlign: "center",
                            borderRadius: "8px",
                            border: "1px solid #c8e6c9",
                            backgroundColor: "#e8f5e9",
                        }}
                    >
                        Thank you for submitting your files. They have been
                        uploaded successfully!
                    </CardContent>
                ) : (
                    <CardContent>
                        <Typography
                            variant="h4"
                            gutterBottom
                            align="center"
                            style={{
                                fontWeight: 600,
                                color: "#333",
                                fontSize: "2rem",
                                margin: "20px",
                            }}
                        >
                            Upload Your Files
                        </Typography>

                        <Typography
                            variant="body1"
                            gutterBottom
                            align="center"
                            style={{
                                fontSize: "1.3rem",
                                marginBottom: "30px",
                                color: "black",
                            }}
                        >
                            <strong
                                style={{ color: "#f08733", fontWeight: 700 }}
                            >
                                Dear Patient,
                            </strong>
                            <br />
                            <strong
                                style={{ color: "#f08733", fontWeight: 700 }}
                            >
                                {orgName}
                            </strong>{" "}
                            has requested that you upload your files. Please use
                            the link below to securely submit them.
                        </Typography>

                        <div style={{ marginBottom: "25px" }}>
                            <Button
                                variant="contained"
                                component="label"
                                startIcon={<CloudUploadIcon />}
                                fullWidth
                                style={{
                                    backgroundColor:
                                        files.length >= MAX_FILE_COUNT
                                            ? "#cccccc"
                                            : "#f08733", // Gray when disabled, orange when enabled
                                    color: "white",
                                    padding: "12px 20px",
                                    textTransform: "none",
                                    fontSize: "0.9rem",
                                    borderRadius: "8px",
                                }}
                                disabled={files.length >= MAX_FILE_COUNT} // Disable button when max file count is reached
                            >
                                Select Your Files
                                <input
                                    type="file"
                                    accept=".jpeg, .jpg, .png, .pdf, .doc, .docx, .txt, .rtf"
                                    onChange={handleFileChange}
                                    hidden
                                    multiple
                                />
                            </Button>
                        </div>
                        <div>
                            {files.map((file, idx) => {
                                return (
                                    <TextField
                                        key={file.name}
                                        label="Attached File"
                                        variant="outlined"
                                        value={file.name}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    onClick={() =>
                                                        handleDelete(idx)
                                                    }
                                                >
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            ),
                                        }}
                                        disabled
                                        style={{ marginBottom: "20px" }}
                                    />
                                );
                            })}
                        </div>

                        {files.length > 0 ? (
                            <div style={{ marginTop: "20px" }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: "#f08733",
                                        color: "white",
                                        border: "none",
                                        padding: "12px 20px",
                                        fontSize: "0.9rem",
                                        cursor: "pointer",
                                        borderRadius: "8px",
                                        fontWeight: "bold",
                                        transition:
                                            "background-color 0.3s",
                                        textTransform: "none",
                                    }}
                                    onClick={handleSubmit}
                                    startIcon={
                                        loading ? (
                                            <CircularProgress size={20} />
                                        ) : null
                                    }
                                    fullWidth
                                    disabled={loading}
                                >
                                    {loading
                                        ? "Uploading..."
                                        : "Save & Submit"}
                                </Button>
                            </div>
                        ) : (
                            ""
                        )}
                    </CardContent>
                )}
            </Card>

         
        </>
    );
       {/* Footer section added here */}
            <ReportFooter />
};

export default FileUploader
