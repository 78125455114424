import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useConfirmationDialogStyles from '../../styles/ConfirmationDialogStyle';
import { Button } from '../../styles/CustomButtons';

export default function ConfirmationDialog ({ 
    open,
    icon: Icon = CheckCircleIcon,
    isLoading,
    title,
    text,
    textStyle,
    confirmButtonTitle = 'Yes',
    closeButtonTitle = 'No',
    onClose,
    onConfirm
}) {
    const classes = useConfirmationDialogStyles();

    return (
        <Dialog open={open} className={classes.root}>
            <div className={classes.wrapper}>
                <Icon className={classes.icon}/>

                {title && <DialogTitle className={classes.title} disableTypography>{title}</DialogTitle>}

                <DialogContent >
                    <DialogContentText className={classes.content} style={textStyle}>{text}</DialogContentText>
                </DialogContent>

                <div className={classes.actionButtons}>
                    {isLoading 
                        ? <CircularProgress style={{margin: '5px auto 10px'}}/>
                        : <>
                            {onConfirm && 
                                <Button className={classes.button} onClick={onConfirm}>{confirmButtonTitle}</Button>
                            }
                            <Button className={classes.button} onClick={onClose}>{closeButtonTitle}</Button>
                          </>
                    }
                </div>
            </div>
        </Dialog>
    );
};
