import React, { useState, useEffect } from "react";
// Routing
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
// Components
import LoginPage from "./components/LoginPage";
import DrawerMenu from "../shared/DrawerMenu";
import TimeExpiredError from "../shared/TimeExpiredError";

import PrivacyPolicy from "./components/PrivacyPolicy";
import ContactUs from "./components/ContactUs";
import TermsAndConditions from "./components/TermsAndConditions";
import { CircularProgress, Box } from "@material-ui/core";
// Other
import useStyles from "../../styles/LoginStyle/LoginRouting";
import apiConfigs from "../shared/utilities/apiConfigs";
import { api } from "../shared/utilities/api";
import useStore from "../shared/store";
import moment from "moment";
import firebase from "firebase/app";
import "firebase/firestore";
import { createBrowserHistory } from "history";
import EarlyError from "../shared/EarlyError";
import GetPatientReports from "../GetPatientReports/GetPatientReports";
import UploadPatientReports from "../uploadPatientReports/UploadPatientReport";

function checkTimeRange(start, end) {
    const now = new Date();
    const startTime = new Date(start);
    const endTime = new Date(end);

    return {
        isWithinTimeRange: now >= startTime && now <= endTime,
        isEarly: now < startTime,
        isLate: now > endTime,
    };
}

export default function LoginRouting() {
    const [tokenLoginResponse, setTokenLoginResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [appointmentTimeRange, setAppointmentTimeRange] = useState({
        isEarly: false,
        isLate: false,
        isWithinTimeRange: false,
    });
    const [appointmentLoading, setAppointmentLoading] = useState(true);
    const [appointment, setAppointment] = useState(null);

    const { setLogin, setOrg, setUser, setWhiteLabel, envVars, login } = useStore();

    const pingInterval = envVars.appPing;
    const classes = useStyles();
    const history = createBrowserHistory({ forceRefresh: true });

    useEffect(() => {
        async function handleTokenLogin() {
            const result = await api(
                apiConfigs.baseOption("POST", "auth/tokenlogin", {
                    headers: {
                        "auth-token": localToken,
                        "Access-Control-Allow-Origin": "*",
                    },
                })
            );
            if(result) {
                firebase
                    .auth()
                    .signInWithCustomToken(result.data.firebaseToken)
                    .then((event) => {
                        setTokenLoginResponse(result);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
        //Check if there is token. If true, the user is logged in and can be forwarded to the dashboard
        const localToken = localStorage.getItem("token");

        if (localToken) {
            console.log(localToken);
            handleTokenLogin();
        } else {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (tokenLoginResponse) {
            if (tokenLoginResponse.status === 200) {
                setLogin(true);
                setOrg(tokenLoginResponse.data.default_org);
                setWhiteLabel(
                    tokenLoginResponse.data.default_org.whiteLabelURL
                );
                setUser(tokenLoginResponse.data.user.document);
                setIsLoading(false);
            } else {
                localStorage.removeItem("token");
                setLogin(false);
                setOrg({});
                setWhiteLabel({});
                setUser({});
                setIsLoading(false);
                history.push("/");
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenLoginResponse]);

    const getUrlParams = () => {
        return new URLSearchParams(window.location.search);
    };

    const checkPatientURL = () => {
        const params = getUrlParams();
        return params.get("doctor") && params.get("org");
    };

    useEffect(() => {
        const fetchAppointment = async (id) => {
            try {
                const result = await api(
                    apiConfigs.baseOption(
                        "GET",
                        `appointments/fetchAppointment/${id}`,
                        { headers: { "Content-Type": "application/json" } }
                    )
                );

                if (result.status !== 200) {
                    throw new Error("unable to fetch appointment");
                }

                setAppointment(result.data);
                //validate if the current time is inbetween the start and end time of the appointment:
                const start = result.data.start;
                const end = result.data.end;

                setAppointmentTimeRange(checkTimeRange(start, end));
            } catch (err) {
                // fail silently
            }
            setAppointmentLoading(false);
        };
        const params = getUrlParams();
        const appointmentId = params.get("appointmentid");
        if (appointmentId) {
            fetchAppointment(appointmentId);
        }
    }, []);

    // check if passed token. this may come from admin app or other app
    const getTokenFromURL = () => {
        const params = getUrlParams();
        return params.get("token");
    };

    console.log("path1",window.location.pathname)
    const updatePatient = (patient) => {
        let currentDate = new Date();
        firebase
            .firestore()
            .collection("patient-queue")
            .doc(patient.id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    firebase
                        .firestore()
                        .collection("patient-queue")
                        .doc(patient.id)
                        .update({
                            lastUpdated:
                                firebase.firestore.Timestamp.fromDate(
                                    currentDate
                                ),
                        })
                        .then(() => {
                            // Update local session
                            patient.lastUpdated = moment(currentDate).format();
                            if (localStorage.getItem("session")) {
                                localStorage.setItem(
                                    "session",
                                    JSON.stringify(patient)
                                );
                                console.log("Client last active time updated.");
                            }
                        })
                        .catch((error) => {
                            console.error("Error updating document: ", error);
                        });
                }
            })
            .catch((error) => {
                console.log("Error getting document:", error);
            });
    };

    const urlToken = getTokenFromURL();
    if (urlToken) {
        localStorage.setItem("token", urlToken);
        console.log("url token ", urlToken);
        history.push("/");
    }

    const isReportRoute = window.location.pathname.startsWith('/getReport');
    const isUploadRoute = window.location.pathname.startsWith('/uploadReport');
    console.log("path",window.location.pathname)

    if (isReportRoute) {
        return (
            <Router>
                <Switch>
                    <Route path="/getReport/:orgName/:orgId/:hashedId/:sessionId">
                        <GetPatientReports />
                    </Route>
                </Switch>
            </Router>
        );
    }
    else if (isUploadRoute) {
        return (
            <Router>
                <Switch>
                    <Route path="/uploadReport/:orgName/:orgId/:userId/:hashedId/:sessionId">
                        <UploadPatientReports />
                    </Route>
                </Switch>
            </Router>
        );
    }

    // We already have a patient session
    else if (localStorage.getItem("session")) {
        // TODO: Check in the DB if the params provided are a valid org and doctor. If not, show a message and redirect to login screen.

        let patient = localStorage.getItem("session");
        if (patient) {
            // If the patient has been out of the patient queue for greater than pingInterval minutes, restart their session
            const lastPing = JSON.parse(patient).lastUpdated;
            const restartSession =
                moment().diff(lastPing, "minutes") > pingInterval / 60000;

            if (restartSession) {
                localStorage.removeItem("session");
            } else {
                // Otherwise, we can update their last active time
                updatePatient(JSON.parse(patient));
            }
        }

        return <DrawerMenu />;
    } else if (checkPatientURL()) {
        const params = getUrlParams();
        const appointmentId = params.get("appointmentid");

        if (!appointmentId || appointmentTimeRange.isWithinTimeRange) {
            return <DrawerMenu />;
        } else if (!appointmentLoading) {
            if (appointment && appointmentTimeRange.isEarly) {
                return (
                    <Box className={classes.pageRefresh}>
                        <EarlyError
                            startTime={moment(appointment.start).format(
                                "DD/MM/YYYY hh:mm A"
                            )}
                        />
                    </Box>
                );
            } else {
                return (
                    <Box className={classes.pageRefresh}>
                        <div>
                            <TimeExpiredError />
                        </div>
                    </Box>
                );
            }
        }
        return (
            <Box className={classes.pageRefresh}>
                <CircularProgress />
            </Box>
        );
    } else {
        // The user entering is a provider, MOA, Hadmin etc.
        return (
            <>
                <Router>
                    <Switch>
                        {login
                            ?
                                <Route path={["/:whiteLabel", "/"]}>
                                    <DrawerMenu />
                                </Route>
                            : isLoading
                                ?
                                    <Box className={classes.pageRefresh}>
                                        <CircularProgress />
                                    </Box>
                                :
                                    <Route path={["/:whiteLabel", "/"]}>
                                        <LoginPage />
                                    </Route>
                        }

                        <Route
                            path="/privacy-policy"
                            component={PrivacyPolicy}
                        />
                        <Route
                            path="/terms-conditions"
                            component={TermsAndConditions}
                        />
                        <Route path="/contact-us" component={ContactUs} />
                    </Switch>
                </Router>
            </>
        );
    }
}
