import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import React from "react";
import settingStyle from "../../styles/DialogStyle/components/settingsPageStyles";
import Announcements from "./component/ClinicSetting/components/Announcements";
import HumburgerButton from "../shared/HumburgerButton";

const SettingPage = ({ smallScreenView, handleMenuClick }) => {
    const classes = settingStyle();

    return (
        <>
            <HumburgerButton smallScreenView={smallScreenView} handleMenuClick={handleMenuClick} />            
            
            <Container style={{ padding: '0px' }}>
                <Box display="flex" style={{ marginTop: 32 }}>
                    <Typography className={classes.labelLarge}>
                        Meet App Settings
                    </Typography>
                </Box>
                
                <Box className={classes.appBox}>
                    <Announcements />
                </Box>
            </Container>
        </>
    );
};

export default SettingPage;
