import { Tab, Tabs, makeStyles, withStyles } from '@material-ui/core';

const drawerWidth = 240;

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        height: '340',
    },
    drawerPaper: {
        width: drawerWidth,
        height: '110',
    },
    content: {
        flexGrow: 1,
        backgroundColor: '#F5F6FA',
        padding: theme.spacing(3),
    },
    dashboardContent: {
        flexGrow: 1,
        backgroundColor: '#F5F6FA',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        
    },
    logo: {
        marginTop: '3rem',
        marginBottom: '1rem',
    },
    account: {
        marginBottom: '3rem',
    },
    avatar: {
        width: "100%",
        height: "100%",
    },
    // doctor name under avatar
    user: {
        fontSize: 20,
        fontWeight: 700,   
         marginTop: "-38px",
         color: theme.palette.primary.orange,

    },
    accountSettingLink: {
        textDecoration: 'none',
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.primary.orange,
    },
    icon: {
        marginTop: "0.3rem",
        marginRight: "1.2rem",
        marginLeft: "2rem",
    },
    labelIcon: {
        minHeight: "80%"
    },
    iconLabelWrapper: {
        flexDirection: "row",
        justifyContent: "left",
    },
    testBtn: {
        color: theme.palette.primary.orange,
        border: `1.5px solid ${theme.palette.primary.orange}`,
        fontWeight: 500,
        fontSize: 16,
        minWidth: 153,
        '&:hover': {
            opacity: 1,
        },
    },
    signOut: {
        marginTop: '1rem',
        marginLeft: '2rem',
    },
    signOutBtn: {
        textTransform: 'none',
        color: 'white',
        background: "radial-gradient(73.95% 73.95% at 14.58% 11.46%, rgb(67, 147, 221) 0%, rgb(0, 63, 186) 100%)",
        fontWeight: 500,
        width: "100%",
        marginLeft: "-14px",
        margin: "-8px",
        fontSize: theme.typography.pxToRem(15),
        '&:hover': {
            opacity: 1,
            color: 'white',
            background: "radial-gradient(73.95% 73.95% at 14.58% 11.46%, rgb(67, 147, 221) 0%, rgb(0, 63, 186) 100%)",


        },
    },
    signOutBtnIcon: {
        marginRight: "1.3rem",
    },
    copyright: {
        marginBottom: "3rem",
        marginTop: "1rem",
    },
    copyRightText: {
        fontSize: 12,
        fontWeight: 400,

    },
    preCall: {
        marginTop: '15rem',
    },
    preCallBtn: {
        width: "100%",
        margin: "15px 15px 0",
    }
}));

// vetical tab styles
export const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        width: 8,
        '& > span': {
            maxWidth: 40,
            width: '100%',
            background: 'radial-gradient(73.95% 73.95% at 14.58% 11.46%, #003FBA 0%, #003FBA 100%);',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#767676',
        fontWeight: 550,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        '&:focus': {
            opacity: 1,
            color: "Black",
        },
        '&:hover': {
            opacity: 1,
        },
        '&$selected': {
            color: "#003FBA",
        },
    },
    selected: {},
    wrapper: {},
    labelIcon: {},
}))((props) => <Tab disableRipple {...props} />);
