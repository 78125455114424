import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  // Main container styles to ensure the footer sticks to the bottom
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh", // Ensure the container takes the full height of the viewport
  },

  mainContent: {
    flex: 1, // Allows the main content to grow and push the footer down
  },

  // Footer styles
  footer: {
    backgroundColor: "#A3C1DA", // Background color for the footer
    paddingTop: "20px", // Padding around the content
    paddingBottom: "20px",
    width: "100%", // Full width for the footer
    position: "relative", // Relative positioning
    bottom: "0", // Positioned at the bottom
    left: "0", // Aligned to the left
    textAlign: "center", // Center the content
    zIndex: 1000, // Ensure it stays above other elements
  },

  // Footer text container
  footerText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down(440)]: {
      fontSize: "0.9rem", // Smaller font size for small screens
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "0.8rem", // Even smaller for very small screens
      paddingLeft: "2%",
      paddingRight: "2%",
    },
  },

  // Links list styles
  linkList: {
    listStyleType: "none", // Remove bullets
    padding: 0, // Remove padding
    margin: "10px 0", // Space between list items and other content
    display: "flex", // Use flexbox for horizontal alignment
    justifyContent: "center", // Center the list
  },

  // Individual list item styles
  listItem: {
    margin: "0 10px", // Horizontal spacing between items
  },

  // Footer image styles
  portonImgFooter: {
    width: "auto", // Responsive width
    height: "60px", // Default height for the image
    "@media (max-width: 768px)": {
      height: "50px", // Adjust height for smaller screens
    },
  },
}));  // <- Make sure this is present
