import { withStyles, Grid, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

const HumburgerButton = withStyles({
    resetPadding: {
        padding: '0px',
    },
    hamburgerIcon: {
        width: "30px",
        height: "30px",
        color: "black",
    },
})(({ smallScreenView, handleMenuClick, classes }) => (
    <>
        {smallScreenView && 
            <Grid item xs={12}>
                <IconButton color="primary" component="div" className={classes.resetPadding} onClick={handleMenuClick}>
                    <MenuIcon className={classes.hamburgerIcon}/> 
                </IconButton>
            </Grid>
        }
    </>
));

export default HumburgerButton;
