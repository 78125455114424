import React from "react";

export default function EarlyError({startTime}) {
    return (
        <div style={{backgroundColor:"white",padding:"30px", boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"}}>
            <h1>
                Hello, <span style={{color:"#347abc"}}>Patient!</span>
            </h1>
            <div className="h-text">
                <h4>
                    <br />
                    Sorry! Your Appointment has not yet started.
                    <br /><br />
                    Your scheduled appointment is on <strong style={{color:"#347abc"}}>{startTime}</strong> 
                    <br />
                    <br />                    
                    If you require any further assistance please contact your clinic
                </h4>
            </div>
        </div>
    );
};
