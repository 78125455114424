import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

/*
  Used for any user notification that may be requried. E.g, there is a network error and the user should be
  notified that their request was not successful.

  Types:
  error
  warning
  info
  success
*/

export default function CustomAlert({ description, type, openAlert, setOpenAlert, duration = 4000 }) {

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <div>
      <Snackbar open={openAlert} autoHideDuration={duration} onClose={handleClose}>
        <MuiAlert elevation={6} onClose={handleClose} severity={type}>
          {description}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};
