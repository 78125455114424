import React from "react";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import TechnicalSupport from "../shared/components/TechnicalSupport";
import useStyles from "../../styles/DialogStyle/components/helpPageStyles";
import useStore from '../shared/store';
import HumburgerButton from "../shared/HumburgerButton";

const EMAIL_TYPES = {
	TECH_SUPPORT: 'tech_support',
    CONTACT_CLINIC: 'contact_clinic'
};

const Help = ({ smallScreenView, handleMenuClick }) => {
    const classes = useStyles();
    const { user } = useStore();

    const isPatient = !user.roles;

    return (
        <>
           <HumburgerButton smallScreenView={smallScreenView} handleMenuClick={handleMenuClick} />
            <Container style={{padding: '0px'}}>
                <Box display="flex" style={{ marginTop: 32 }}>
                    <Typography className={classes.labelLarge}>Help</Typography>
                </Box>

                <Box className={classes.appBox}>
                    <TechnicalSupport emailType={EMAIL_TYPES.TECH_SUPPORT}/>
                </Box>
                {isPatient &&
                    <Box className={classes.appBox}>
                        <TechnicalSupport emailType={EMAIL_TYPES.CONTACT_CLINIC}/>
                    </Box>
                }
            </Container>
        </>
    );
   

}
export default Help;
