import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core';
import LoginRouting from './pages/login/LoginRouting';
import theme from './styles/GlobalStyle';
import apiConfigs from './pages/shared/utilities/apiConfigs';
import { CircularProgress, Box } from '@material-ui/core';
import { api } from './pages/shared/utilities/api';
import useStyles from './styles/LoginStyle/LoginRouting';
import useStore from './pages/shared/store';
import firebase from 'firebase/app';
import 'firebase/firestore';

const App = () => {
	const [loading, setLoading] = useState(true);
	const classes = useStyles();
	const { setEnvVars } = useStore();

	const isReportRoute = window.location.pathname.startsWith('/getReport');
	const isUploadRoute = window.location.pathname.startsWith('/uploadReport');

	const setupFirebaseConfig = async () => {
		const result = await api(
			apiConfigs.baseOption('GET', 'auth/get-env', { headers: { "Content-Type": 'application/json' } })
		);
		firebase.initializeApp(result.data.firebaseEnv);
		setEnvVars(result.data.clientEnv);
		setLoading(false);
	};

	useEffect(() => {
		console.log("path1",window.location.pathname)
		// Only setup Firebase if not on the report or upload route
		if (!isReportRoute && !isUploadRoute) {
			console.log("inside IF")
			setLoading(false)
			setupFirebaseConfig();

		} else {
			setLoading(false);
		}
	}, [isReportRoute, isUploadRoute, setEnvVars]);

	return (
		<ThemeProvider theme={theme}>
			{
				loading
					? <Box className={classes.pageRefresh}><CircularProgress /></Box>
					: <LoginRouting />
			}
		</ThemeProvider>
	);
};

export default App;
