import React, {useRef, useState, useEffect} from 'react'
import useStyles from '../../../styles/PreCallTestStyle/Video';
import CustomAlert from '../../shared/components/CustomAlert';
import { CircularProgress } from '@material-ui/core';
// Other 
import { detect } from 'detect-browser';

export default function Video({ devicesSelection, mediaStream, setMediaStream, permissions }) {

  const videoEl = useRef(null);
  const classes = useStyles();  
  const [currentStream, setCurrentStream] = useState(null);
  const [error, setError] = useState('');
  const [errorDuration, setErrorDuration] = useState(4000);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  // Default constraints
  let constraints = useRef(window.constraints = { audio: true, video: true });
  const browser = detect();
  
  useEffect(() => {
    init().then(stream => {
      setCurrentStream(stream)
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (permissions.audio === 'granted' && permissions.video === 'granted') {
      init().then(stream => {
        setCurrentStream(stream)
      });
    }
    // eslint-disable-next-line
  }, [permissions])

  useEffect(() => {
    // Watch for changes in settings and apply the chages to the current media stream
    if(
      // We do not want to re-render after passing inital values to the settings
      !(devicesSelection.video === mediaStream.videoTrack 
      && devicesSelection.audioIn === mediaStream.audioInTrack)
      ){

      // Stop the stream if currently running
      if(currentStream) {
        currentStream.getVideoTracks().forEach( (track) => {
          track.stop();
        });
      }

      navigator.mediaDevices.enumerateDevices().then(devices => {
        // Get the device ID's needed for the constraint
        let audioInId;
        let videoId;

        devices.forEach(device => {
          if(device.label === devicesSelection.video && device.kind === 'videoinput') {
            videoId = device.deviceId;
          }
          if(device.label === devicesSelection.audioIn && device.kind === 'audioinput') {
            audioInId = device.deviceId;
          }
        });
        
        // New constraints
        constraints.current = window.constraints = {
          audio: {deviceId: audioInId ? {exact: audioInId} : undefined},
          video: {deviceId: videoId ? {exact: videoId} : undefined}
        };

        // Init video and audio in
        init().then(stream => {
          setCurrentStream(stream);
        });
      });
    }

    if(!(devicesSelection.audioOut === mediaStream.audioOutTrack)) {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        // Get the device ID's needed for the constraint
        let audioOutId;

        devices.forEach(device => {
          if(device.label === devicesSelection.audioOut && device.kind === 'audiooutput') {
            audioOutId = device.deviceId;
          }
        });

        if(audioOutId) attachSyncID(videoEl.current, audioOutId, devicesSelection.audioOut);
      });
    }
    // eslint-disable-next-line
  }, [devicesSelection]);

  useEffect(() => {
    // On dismount close the current stream
    return () => {
      if(currentStream) {
        currentStream.getVideoTracks().forEach( (track) => {
          track.stop();
        });
      }
    }
  }, [currentStream]);

  const attachSyncID = (videoElement, syncId, audioOutTrack) => {
    if(typeof syncId !== 'undefined') {
      videoElement.setSinkId(syncId)
        .then(() => setMediaStream({ ...mediaStream, audioOutTrack }))
        .catch(error => {
          let errorMessage = error;
          if(error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.warn(errorMessage);
        });
    } else {
      setError('Browser does not support output device selection.');
    };
  };

  const handleSuccess = (stream) => {
    // Each track is a different/available media device
    const videoTrack = stream.getVideoTracks()[0].label;
    const audioInTrack = stream.getAudioTracks()[0].label;
    if(mediaStream.audioOutTrack) {
      setMediaStream({ ...mediaStream, videoTrack, audioInTrack });
    } else {
      setMediaStream({ videoTrack, audioInTrack, audioOutTrack: audioInTrack });
    }
    
    window.stream = stream;
    // Holds the source of the media stream
    videoEl.current.srcObject = stream;
  };

  const handleError = (error) => {
    console.log("ERROR: " + error)
    if (error.name === 'ConstraintNotSatisfiedError') {
      setError(`The resolution ${constraints.current.video.width.exact}x${constraints.current.video.height.exact} px is not supported by your device.`);
      setErrorDuration(8000);
    } else if (error.name === 'PermissionDeniedError' || error.name === 'NotAllowedError') {
      setErrorDuration(8000);
      if (browser.name === 'safari') {
        setError('Permissions have not been granted to use your camera and microphone. Please grant permission and reopen the pre-call test.');
      } else {
        setError('Permissions have not been granted to use your camera and microphone. Please grant permission');
      }
    } else if(error.name === 'NotReadableError'){
      setError(`Mic error: ${error.name}` + ", Recommend using Chrome for best experience");
    }
    else {
      if(error.name === "NotFoundError"){
        setError('Your video source could not be detected. Please try reconnecting or resetting and try again');
      }else {
        setError(`Video error: ${error.name}`);
      }
    }
  };

  const init = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints.current);
      setLoading(false);
      handleSuccess(stream);
      // Prevent the video element from recieving clicks
      videoEl.current.disabled = true;
      return stream;
    } catch(error) {
      handleError(error);
      setOpenAlert(true);
      return null;
    }
  };

  return (
    <>
    <div className={classes.videoStreamContainer}>
      { loading ? <CircularProgress className={classes.videoLoading}/> :
        <video className={classes.videoStream} ref={videoEl} autoPlay playsInline></video>
      }
    </div>
    <CustomAlert description={error} type="error" openAlert={openAlert} setOpenAlert={setOpenAlert} duration={errorDuration}/>
    </>
  );
}
