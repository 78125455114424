import { makeStyles, createTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';


export const customInputTheme = createTheme({
  palette: {
    primary: grey,
  },
});


export default makeStyles((theme) => ({

  loginButton: {
    background: 'radial-gradient(73.95% 73.95% at 14.58% 11.46%, #EE8F37 0%, #E47029 100%)',
    borderRadius: '8px',
    fontWeight: '600',
    color: '#FFFFFF',
    marginTop: '10px'
  },

  loginContainer: {
    width: '540px',
    padding: '40px',
    [theme.breakpoints.down(310)]: {
      padding: '30px',
      marginLeft: '2rem',
    },
    [theme.breakpoints.down(290)]: {
      marginLeft: '3rem',
    },
  },

  loginTextField: {
    marginBottom: '20px',
    height: '40px',
  },

  loginTextFieldInner: {
    paddingTop: '9px', 
    paddingBottom: '9px'
  },

  loginTextFieldLabel: {
    color: '#343434',
    fontSize: '12px',
    margin: '0 0 3px 5px'
  },

  container: {
    marginTop: '-170px',
    padding: "0 60px",
    [theme.breakpoints.down(1070)]: {
      marginTop: "-150px"
    },

    [theme.breakpoints.down(550)]: {
      marginTop: "-105px",
      padding: "0 20px",
    },

    [theme.breakpoints.down(390)]: {
      marginTop: "-95px",
      padding: "0 10px",
    },

    [theme.breakpoints.down(350)]: {
      //marginTop: "-20px",
      padding: "0 10px",
    },
  },

  loginFooter: {
    [theme.breakpoints.down(400)]: {
      marginTop: "-110px",
    },
    [theme.breakpoints.down(310)]: {
      marginTop: "-20%",
    },
  },

  loginTitle: {
    marginBottom: '30px',
    [theme.breakpoints.down(325)]: {
      fontSize: "25px",
    },
    [theme.breakpoints.down(290)]: {
      fontSize: "18px",
    },
  },

  loginBox: {
    '@media (max-height: 750px)': {
      paddingTop: '6%',
      paddingBottom: '6%',
    },
    '@media (min-width: 1050px)': {
      minHeight: '78vh',
    },
  },

  loginPage: {
    backgroundColor: '#F5F6FA',
    minHeight: '100vh',
    height: 'auto !important', 
  },

  loginPaper: {
    width: '60%',
    maxWidth: '650px',
    height: 'auto',
    padding: '35px 35px 35px 35px',
    borderRadius: '8px',
    marginTop: '-3%',
    [theme.breakpoints.down(1200)]: {
      width: '44%',
    },
    [theme.breakpoints.down(1100)]: {
      width: '60%',
    },
    [theme.breakpoints.down(600)]: {
      width: '70%',
    },
    [theme.breakpoints.down(300)]: {
      width: '90%',
    },
    [theme.breakpoints.down(310)]: {
      marginLeft: '2rem',
    },
    [theme.breakpoints.down(290)]: {
      marginLeft: '3rem',
    },
  },
}));