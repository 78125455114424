import firebase from 'firebase/app';
import 'firebase/firestore';
import {api} from "./api";
import apiConfigs from "./apiConfigs";

export const updatePermissions = (type, value, patient) => {
    firebase.firestore()
    .collection('patient-queue')
    .doc(patient.id)
    .update({
        [`permissions.${type}`]: value
    }).then(() => {
        console.log("Permission successfully updated.")
    }).catch((error) => {
        console.error("Permission update failed.")
    });
};

// call to set clinic options
// whiteLabel: org whiteLabel from invite url or useStore
// userID, user._id
// ClinicCloseToggle -> to activate provider status
// ClinicScheduleToggle -> for clinic schedule option
// status -> Provider status
// Ophours -> dict for operation hour
export const setClinicOption = (whiteLabel, userID, ClinicCloseToggle, ClinicScheduleToggle, Status, OpHours) => {
    console.log(whiteLabel)
    try{
        firebase.firestore()
            .collection('clinic-option')
            .doc(whiteLabel)
            .set({
                userID: userID,
                ClinicCloseSave: ClinicCloseToggle,
                ClinicScheduleSave: ClinicScheduleToggle,
                ProviderStatusSave: Status,
                OperationHour: OpHours,
            })
    }catch(error){
        console.log(error)
    }

};
//call to get clinic options
export const getClinicOption = async (org)=>{
    try{
        const getter = await firebase.firestore()
            .collection('clinic-option')
            .doc(org)
            .get();
        return getter.data();
    }catch(error){
        console.log(error)
        return null;
    }
}
//call to set clinic option toggle to firebase
export const updateClinicOption = (whiteLabel, userID, ClinicCloseToggle, ClinicScheduleToggle) =>{
    try{
        firebase.firestore()
            .collection('clinic-option')
            .doc(whiteLabel)
            .update({
                userID: userID,
                ClinicCloseSave: ClinicCloseToggle,
                ClinicScheduleSave: ClinicScheduleToggle,
            })
    }catch(error){
        console.log(error)
    }
}

//call to set clinic Provider status to firebase
export const updateClinicProStatus = (whiteLabel, userID, status) =>{
    try{
        firebase.firestore()
            .collection('clinic-option')
            .doc(whiteLabel)
            .update({
                userID: userID,
                ProviderStatusSave: status,
            })
    }catch(error){
        console.log(error)
    }
}
//call to set clinic operation hours to firebase
export const updateClinicOpHours = (whiteLabel, userID, OpHours) => {
    try{
        firebase.firestore()
            .collection('clinic-option')
            .doc(whiteLabel)
            .update({
                userID: userID,
                OperationHour: OpHours,
            })
    }catch(error){
        console.log(error)
    }
};

//call to set clinic announcement to firebase
export const updateClinicAnnouncement = async (whiteLabel, announcement) =>{
    try {
        firebase.firestore()
            .collection('clinic-option')
            .doc(whiteLabel)
            .update({
                announcement,
            })
    } catch(error){
        console.log(error)
    }
}
//call to get operation hours from core api
export const getOperationHoursFromCore = async (user) => {
    try{
        const hours = await api(apiConfigs.baseOption('get', 'appointments/getOpHours', { params: { orgId: user.default_org, limit: 1}}));
        return hours.data.data.OperationHours
    }catch(error){
        console.log("error")
        return null
    }
}

export const getDoctorOption = async (id) => {
    try {
        const getter = await firebase.firestore()
            .collection('doctor-option')
            .doc(id)
            .get();
        return getter.data();
    } catch (error) {
        console.log(error);
    }
};

export const updateDoctorAnnouncement = async (id, announcement) => {
    try {
        firebase.firestore()
            .collection('doctor-option')
            .doc(id)
            .set({ announcement });
    } catch (error) {
        console.log(error);
    }
};
