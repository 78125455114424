import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    Paper,
    CircularProgress,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import AccountIcon from "@material-ui/icons/AccountCircleRounded";
import VideoIcon from "@material-ui/icons/Videocam";
import PhoneIcon from "@material-ui/icons/Phone";
import moment from "moment-timezone";
import HumburgerButton from "../shared/HumburgerButton";
import { api } from "../shared/utilities/api";
import apiConfigs from "../shared/utilities/apiConfigs";
import useStyles from "../../styles/AppointmentsStyle";
import { StyledTableHeadCell } from "../../styles/AppointmentsStyle";
import useStore from "../shared/store";
import aptToday from '../../img/apt-today.png';


const minCounter = (org, dateTime) => {
    let now = org.location ? moment().tz(org.location) : moment();
    let aptTime = org.location
        ? moment(dateTime).tz(org.location)
        : moment(dateTime);
    let diff = aptTime.diff(now, "minutes");
    if (diff <= 15 && diff > 0) {
        return diff;
    }

    if (diff <= 0) {
        return true;
    }

    return false;
};

const AppointmentsPage = ({ smallScreenView, handleMenuClick }) => {
    const classes = useStyles();
    const { org } = useStore();
    const [loading, setLoading] = useState(false);
    const [appointments, setAppointments] = useState([]);

    useEffect(() => {
        setLoading(true);

        const filterApts = async () => {
            const fetchApts = await api(apiConfigs.baseOption('get', 'appointments/getTodayAppointments', { params: { org: org._id } }));

            if (fetchApts && fetchApts.status === 200) {
                const data = fetchApts.data;
                if (data.length > 0) {
                    let aptsToday = [];
                    for (let apt of data) {
                        let current = org.location ? moment().tz(org.location).format() : moment().format();
                        let currentDate = current.substring(0, current.indexOf('T'));
                        if (Object.keys(apt)[0] === currentDate) {
                            aptsToday = aptsToday.concat(apt[Object.keys(apt)[0]]);
                        }
                    }
                    let filteredApts = aptsToday.filter(apt => apt.type === 'Telephone' || apt.type === 'Video');
                    setAppointments(filteredApts);
                }
            }
            setLoading(false);
        }
        filterApts();
    }, [org]);

    return (
        <>
            <HumburgerButton smallScreenView={smallScreenView} handleMenuClick={handleMenuClick} />
            <Container style={{ padding: '0px' }}>
                <Box display="flex" style={{ marginTop: 32 }}>
                    <Typography className={classes.labelLarge}>Appointment For Today</Typography>
                </Box>
                <Box className={classes.appBox}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {loading || !appointments ? (
                                <Grid container component={Paper} className={classes.apt} justifyContent="center" >
                                    <Typography>Please wait</Typography>
                                    <CircularProgress />
                                </Grid>
                            ) : appointments.length ? (
                                <Grid container className={classes.columnPaper}>
                                    <TableContainer component={Paper} >
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableHeadCell>Time</StyledTableHeadCell>
                                                    <StyledTableHeadCell>Name</StyledTableHeadCell>
                                                    <StyledTableHeadCell>Type</StyledTableHeadCell>
                                                    <StyledTableHeadCell>Reason</StyledTableHeadCell>
                                                    <StyledTableHeadCell>Doctor</StyledTableHeadCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {appointments.map((appointment) => (
                                                    <TableRow key={appointment.id} hover className={classes.rowWrapper}>
                                                        <TableCell component="th" scope="row" className={classes.cellWrapper}>
                                                            <div>
                                                                <strong>Time</strong>
                                                                {typeof minCounter(org, appointment.appointmentStartDateTime) === "number"
                                                                    ? `IN ${minCounter(org, appointment.appointmentStartDateTime )} MINUTES` 
                                                                    : `${appointment.startTime.substring(0, appointment.startTime.lastIndexOf(":" ))} - ${appointment.endTime.substring(0, appointment.endTime.lastIndexOf(":"))}`
                                                                }
                                                        </div>
                                                        </TableCell>
                                                        <TableCell className={classes.cellWrapper}>
                                                            <div >
                                                                <strong>Name</strong>
                                                                <div className={classes.iconCell}>
                                                                    <AccountIcon color="primary"/>
                                                                    {appointment.name}
                                                                </div>
                                                        </div>
                                                        </TableCell>
                                                        <TableCell className={classes.cellWrapper}>
                                                            <div>
                                                                <strong>Type</strong>

                                                                <div className={classes.iconCell}>
                                                                    {appointment.type === "Video"
                                                                        ? <><VideoIcon style={{ color: "#CB4D00" }}/> Video Call</>
                                                                        : appointment.type === "Telephone"
                                                                            ? <><PhoneIcon /> Voice Call</>
                                                                            : appointment.type
                                                                    }
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.cellWrapper}>
                                                            <div>
                                                                <strong>Reason</strong>
                                                                {appointment.reason}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.cellWrapper}>
                                                            <div>
                                                                <strong>Doctor</strong>
                                                                {appointment.doctor_name}
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            ) : (
                                <Grid container component={Paper} className={classes.todaysAppointments}>
                                        <Grid item xs={12} container justifyContent='center' className={classes.imageText}>
                                            <img src={aptToday} alt="aptToday" width="200" />
                                        </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" align="center">No Appointments Today</Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.aptCell}>
                                        <Typography align="center">Your clinic does not have an apooinmnet for today</Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
};

export default AppointmentsPage;
