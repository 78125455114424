import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        border: "2px solid rgba(255,255,255,0)",
        borderRadius: "8px"
    },
    rootExpanded: {
        border: `2px solid ${theme.palette.primary.orange}`,
        borderRadius: "8px",
        padding: "3px",
    },
    appBox: {
        margin: "2rem 0",
    },
    hamburger: {
        width: "30px",
        height: "30px",
        color: "black",
    },
    labelLarge: {
        fontSize: "32px",
        color: "black",
        fontWeight: 500,
        width: "100%",
        margin:"auto 0"
    },
    labelWithEdit: {
        display: "flex",
        justifyContent: "space-between",
        cursor: 'pointer',
    },
    labelExpanded: {
        color: theme.palette.primary.orange,
        fontSize: "1.2rem",
        fontWeight: 500,
        width: "100%",
        margin:"auto 0",
    },
    labelCollapsed: {
        color: "black",
        fontSize: "1.2rem",
        fontWeight: 500,
        width: "100%",
        margin:"auto 0",
    },
    edit: {
        fontSize: "0.8rem",
        color: "#003FBA",
        fontWeight: 600,
        marginRight: "0.5rem",
        cursor: "pointer"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    paperComponment: {
        padding: "1rem 1rem"
    },
    reportIssueBtn: {
        color: "white",
        padding: '8px 50px',
        background:"radial-gradient(73.95% 73.95% at 14.58% 11.46%,#ee8f37 0,#e47029 100%)",
        border: "#e47029",
    },
    reportIssueHeader: {
        padding: "10px",
        background: "radial-gradient(73.95% 73.95% at 14.58% 11.46%, #4393DD 0%, #003FBA 100%)",
        color: "#FFFFFF",
        textAlign: "center"
    },
    reportIssueDescription: {
        backgroundColor: "#E0EAF6",
        padding: "15px 20px",
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "Roboto",
        textAlign: "center",
    },
    acceptedFilesLabel: {
        color: "#347ABC",
        fontSize: "15px",
        marginTop: "5px",
        fontWeight: "500",
    },
    fullWidth: {
        width: "100%",
    },
    uploadButton: {
        textTransform: 'none',
        marginTop: "5px",
    },
    uploadInput: {
        display: 'none',
    },
    fileRow: {
        paddingTop: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontFamily: 'Roboto',
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)',
        },
        '& svg': {
            color: 'rgba(0, 0, 0, 0.45)',
        }
    },
    attachIcon: {
        marginRight: '10px',
    },
    clearIcon: {
        cursor: 'pointer',
        '&:hover': {
            color: '#424242',
        },
    },
    sendReportBtn: {
        color: "white",
        width: "100%",
        background:"radial-gradient(73.95% 73.95% at 14.58% 11.46%,#ee8f37 0,#e47029 100%)",
        border: "#e47029",
    },
    actionButtons: {
        margin: '5px 20px 30px',
    },
    expandedWrapper: {
        padding: '20px 20px 35px',
    },
    expandedDescription: {
        color: 'black'
    },
    expandedReportRow: {
        textAlign: 'center' 
    },
    formWrapper: {
        padding: '10px 30px',
    }
}));
