import React from 'react'
import { Typography, Paper } from '@material-ui/core'
import useStyles from '../../../styles/LoginStyle/ContactUs';
// Components
import Header from './Header';
import Footer from './Footer';

export default function ContactUs() {
  const classes = useStyles();
  return (
    <>
      <Header/>
      <Paper className={classes.container}>
        <Typography className={classes.header}>BACK TO HOME</Typography>
        <Typography variant="h3" className={classes.title}>Contact Us</Typography>
        <div className={classes.content}>
            <Typography variant="h4">Porton Health</Typography>
            <br />
            <Typography className={classes.subHeading}>422-268 Keefer Street<br />
            Vancouver, BC V6A 1X5</Typography>
            <br />
            <Typography className={classes.subHeading}>Phone: +1 778-358-6222</Typography>
            <br />
            <Typography className={classes.subHeading}>Monday to Friday<br />
            8:00am to 5:00pm PST</Typography>
            <br />
            <Typography>Closed on weekends and statutory holidays</Typography>
        </div>
      </Paper>
      <Footer/>
    </>
  )
}
