const axios = require("axios");

export const api = async (options) => {

  try {
    const result = await axios(options);
    if (result && result.status === 200) {
      return { status: 200, data: result.data };
    } else {
      return result;
    }

  } catch(e) {
    if (e.response && e.response.status) {
      return { status: e.response.status, error: e };
    } else {
      return { status: 500, error: e };
    }
  }
};