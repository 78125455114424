import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EventIcon from "@material-ui/icons/Event";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PeopleIcon from "@material-ui/icons/People";
import "firebase/firestore";
import { Link } from "react-router-dom";
import useStore from "../shared/store";
import { api } from "../shared/utilities/api";
import apiConfigs from "../shared/utilities/apiConfigs";

// Function to get the time of day greeting
const getTimeOfDayGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) {
        return "Good Morning";
    } else if (hour < 18) {
        return "Good Afternoon";
    } else {
        return "Good Evening";
    }
};

const useStyles = makeStyles({
    clickableCard: {
        cursor: "pointer",
        backgroundColor: "white",
        borderRadius: "8px",
        height: "180px", // Uniform height for all cards
        textAlign: "center",
        transition: "background-color 0.3s",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Soft shadow effect
        "&:hover": {
            backgroundColor: "#e47029",
            "& $title, & $subtitle, & $icon": {
                color: "white",
            },
        },
    },
    title: {
        fontSize: "20px",
        fontWeight: 600,
        color: "#e47029",
        marginTop: "10px", // Add some space between icon and title
    },
    subtitle: {
        fontSize: "16px",
        color: "#e47029",
        fontWeight: 400,
        marginTop: "5px", // Slight spacing between title and subtitle
    },
    icon: {
        fontSize: "40px", // Larger icon size to match the image
        color: "#e47029",
    },
});

const Dashboard = ({ smallScreenView, handleMenuClick }) => {
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const [totalMessagesCount, setTotalMessagesCount] = useState(0);
    const classes = useStyles();
    const { org, user, envVars } = useStore();
    const emrURL = org?.emr?.emrURL;
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();

    const getUnreadMessagesCount = async () => {
        try {
            const userId = user._id;
            const orgId = user.orgs[0];
            const result = await api(
                apiConfigs.baseOption(
                    "get",
                    `providerMessages/${orgId}_${userId}`
                )
            );
            let unreadCount = 0;
            if (result?.data?.messages?.length) {
                result.data.messages.forEach((message) => {
                    if (!message.read) {
                        unreadCount += 1;
                    }
                });
            }
            setUnreadMessagesCount(unreadCount);
            setTotalMessagesCount(result?.data?.messages?.length || 0);
        } catch (error) {
            setUnreadMessagesCount(0);
            setTotalMessagesCount(0);
        }
    };

    useEffect(() => {
        if (user) {
            getUnreadMessagesCount();
        }
    }, [user]);

    return (
        <>
            <Card>
                <Box
                    style={{
                        marginTop: 8,
                        marginBottom: 8,
                        paddingRight: 8,
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Typography variant="h4" style={{ color: "black" }}>
                        {new Date().toLocaleDateString([], {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        })}
                    </Typography>
                </Box>
            </Card>
            <Card
                elevation={0}
                style={{ backgroundColor: "#e47029", borderRadius: "0px" }}
            >
                <Box
                    style={{
                        marginTop: 8,
                        marginBottom: 68,
                        marginInlineStart: 56,
                    }}
                >
                    <Typography
                        variant="h3"
                        style={{ color: "white", fontWeight: "lighter" }}
                    >
                        {getTimeOfDayGreeting()} Dr,
                        {user.contact_name
                            ? " " + user.contact_name
                            : user.roles && user.roles[0] === "admin"
                            ? "Admin"
                            : user.roles && user.roles[0] === "healthOrgAdmin"
                            ? "HAdmin"
                            : user.roles && user.roles[0] === "oa"
                            ? "MOA"
                            : user.roles && user.roles[0] === "doctor"
                            ? "Doctor"
                            : ""}
                    </Typography>
                    <Typography variant="h4" style={{ color: "white" }}>
                        You have {unreadMessagesCount} unread messages today
                    </Typography>
                </Box>
            </Card>

            <Typography
                style={{
                    fontSize: "36px",
                    paddingTop: "20px",
                    paddingLeft: "56px",
                    color: "#003FBA",
                }}
            >
                Dashboard
            </Typography>

            <Grid container spacing={3} style={{ padding: "20px" }}>
                {/* Documents Delivered Card */}
                <Grid item xs={12} md={6} lg={4}>
                    <Link to="/MessagePage" style={{ textDecoration: "none" }}>
                        <Card className={classes.clickableCard}>
                            <PeopleIcon className={classes.icon} />
                            <Typography className={classes.title}>
                                Documents Delivered
                            </Typography>
                            <Typography className={classes.subtitle}>
                                Unread: {unreadMessagesCount} | Total:{" "}
                                {totalMessagesCount}
                            </Typography>
                        </Card>
                    </Link>
                </Grid>

                {/* Inbox Card */}
                <Grid item xs={12} md={6} lg={4}>
                    <a
                        href={emrURL + "oscar/tickler/ticklerMain.jsp"}
                        style={{ textDecoration: "none" }}
                        target="_blank"
                    >
                        <Card className={classes.clickableCard}>
                            <MailOutlineIcon className={classes.icon} />
                            <Typography className={classes.title}>
                                Inbox (OSCAR Tickler)
                            </Typography>
                            <Typography className={classes.subtitle}>
                                Open Inbox{" "}
                            </Typography>
                        </Card>
                    </a>
                </Grid>

                {/* Daysheet Card */}
                <Grid item xs={12} md={6} lg={4}>
                    <a
                        a
                        href={
                            emrURL +
                            `oscar/provider/appointmentprovideradminday.jsp?year=${year}&month=${
                                month + 1
                            }&day=${day}`
                        }
                        style={{ textDecoration: "none" }}
                        target="_blank"
                    >
                        <Card className={classes.clickableCard}>
                            <EventIcon className={classes.icon} />
                            <Typography className={classes.title}>
                                Daysheet
                            </Typography>
                            <Typography className={classes.subtitle}>
                                Open Daysheet{" "}
                            </Typography>
                        </Card>
                    </a>
                </Grid>
            </Grid>
        </>
    );
};

export default Dashboard;
