import React from 'react'
// Material UI
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import {useMediaQuery, useTheme} from '@material-ui/core'
import { ReactComponent as PortonLogo } from '../../shared/public/img/virtualapplogo.svg'
// Routing
//import { useHistory } from 'react-router-dom'
// Style
import useStyles from '../../../styles/LoginStyle/LoginRouting';

export default function Footer() {

  //const history = useHistory()
  const classes = useStyles();
  const theme = useTheme()
  const middleScreen = useMediaQuery(theme.breakpoints.down(1000))
  const smallScreen = useMediaQuery(theme.breakpoints.down(400))

  return (
    <footer className={classes.footer}>
      <Grid container spacing={2} alignItems="center" direction={middleScreen ? "column" : "row"}>
          <Grid item md={6} xs={12}>
              <Grid container spacing={smallScreen ? 2 : 4} justifyContent='flex-start' wrap='nowrap'>
                  <Grid item xs>
                    <Link component="button" underline='none'>
                        <a href="https://www.portonhealth.com/privacy-and-security/" style={{color:'black', textDecoration:'none'}}>
                            <Typography className={classes.footerText} variant='body2'>Privacy&nbsp;Policy</Typography>
                        </a>
                    </Link>
                  </Grid>
                  <Grid item xs>
                    <Link component="button" underline='none'>
                        <a href="https://www.portonhealth.com/terms-and-conditions/" style={{color:'black', textDecoration:'none'}}>
                            <Typography className={classes.footerText} variant='body2'>Terms&nbsp;&&nbsp;Conditions</Typography>
                        </a>
                    </Link>
                  </Grid>
                  <Grid item xs>
                    <Link component="button" underline='none'>
                        <a href="https://www.portonhealth.com/contact-us/" style={{color:'black', textDecoration:'none'}}>
                            <Typography className={classes.footerText} variant='body2'>Contact&nbsp;Us</Typography>
                        </a>
                    </Link>
                  </Grid>
              </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
              <Grid container justifyContent='flex-end'>
                  <Grid item>
                    <PortonLogo className={classes.portonImgFooter}/>
                  </Grid>
              </Grid>  
          </Grid>
      </Grid>
    </footer>  
  )
}
